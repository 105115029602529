import Delete from "@mui/icons-material/Delete";
import ImageOutlined from "@mui/icons-material/ImageOutlined";
import RefreshOutlined from "@mui/icons-material/RefreshOutlined";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import { User } from "firebase/auth";
import React, { useState } from "react";
import LoadingScreen from "./LoadingScreen";
import { imageModelList } from "./constants";
import { hostname, parseError } from "./utils";

export default function Images({
  currentUser,
  getToken,
  eventListener,
  companyId,
}: {
  getToken: Function;
  eventListener: Function;
  currentUser: any | User;
  companyId: string;
}) {
  const [init, setInit] = useState(false);
  const [images, setImages] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [imageDialogLoading, setImageDialogLoading] = useState(false);
  const [imagePrompt, setImagePrompt] = useState("");
  const [imageSize, setImageSize] = useState("1024x1024");
  const [imageStyle, setImageStyle] = useState("vivid");
  const [imageQuality, setImageQuality] = useState("standard");
  const [imagePreviewOpen, setImagePreviewOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<any>({});
  const [model, setModels] = useState("dall-e-3");

  const GetLoadingIcon = () => {
    return imageDialogLoading ? <CircularProgress size={20} /> : <div></div>;
  };

  const deleteImage = async (e: any, imageId: string) => {
    const currentToken = await getToken();
    setLoading(true);
    axios
      .delete(`${hostname}/images/${imageId}`, {
        params: {
          companyId,
        },
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        getData();
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const previewImage = (e: any, img: any) => {
    setSelectedImage(img);
    setImagePreviewOpen(true);
  };

  const createImage = async () => {
    const currentToken = await getToken();
    setImageDialogLoading(true);
    var req: any = {
      model: model,
      prompt: imagePrompt,
      companyId: companyId,
    };
    if (model === "dall-e-3") {
      req = {
        ...req,
        style: imageStyle,
        size: imageSize,
        quality: imageQuality,
      };
    } else {
      const modelParams = imageModelList.find((x) => x.id === model);
      if (modelParams) {
        req = {
          ...req,
          type: "leonardo",
          width: modelParams.modelWidth,
          height: modelParams.modelHeight,
        };
      }
    }
    axios
      .post(`${hostname}/images`, req, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then(() => {
        getData();
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setImageDialogLoading(false);
        setImageDialogOpen(false);
      });
  };

  const getData = async () => {
    const currentToken = await getToken();
    setLoading(true);
    axios
      .get(`${hostname}/images`, {
        params: {
          companyId,
        },
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        setImages(res.data.images);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fallbackImageSrc =
    "https://i0.wp.com/theantiquewarehousehudsonny.com/wp-content/uploads/2019/12/placehold-3.jpg?resize=300%2C202&ssl=1";
  const handleError = (e: any) => {
    e.target.src = fallbackImageSrc;
  };

  if (currentUser.uid && !init) {
    getData();
    setInit(true);
  }

  if (loading) {
    return <LoadingScreen open={true} />;
  }

  return (
    <Box>
      <div style={{ width: "100%", height: 40 }}>
        <Button
          style={{ marginTop: 10 }}
          startIcon={<ImageOutlined />}
          onClick={() => setImageDialogOpen(true)}
        >
          Create Image
        </Button>
        <IconButton
          onClick={() => getData()}
          style={{ float: "right", marginTop: 10 }}
        >
          <RefreshOutlined />
        </IconButton>
      </div>
      <ImageList variant="masonry" cols={3} gap={8}>
        {images.map((item) => (
          <ImageListItem key={item.url}>
            <img
              src={item.url}
              alt={item.prompt}
              loading="lazy"
              onError={handleError}
              onClick={(e) => previewImage(e, item)}
            />
            <ImageListItemBar
              title={item.prompt}
              actionIcon={
                <IconButton onClick={(e) => deleteImage(e, item.id)}>
                  <Delete />
                </IconButton>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
      <Dialog open={imageDialogOpen} onClose={() => setImageDialogOpen(false)}>
        <DialogTitle>Create Image</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Describe the image you want generated in the prompt
          </DialogContentText>
          <FormControl fullWidth style={{ marginTop: 20 }}>
            <InputLabel>Model</InputLabel>
            <Select
              label="Model"
              value={model}
              onChange={(e) => setModels(e.target.value)}
            >
              {imageModelList.map((imageModel, index) => (
                <MenuItem value={imageModel.id} key={index}>
                  <Chip
                    label={imageModel.provider}
                    style={{ marginRight: 5 }}
                  />
                  {imageModel.tags.map((tag) => (
                    <Chip key={tag} label={tag} style={{ marginRight: 5 }} />
                  ))}
                  {imageModel.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            style={{ marginTop: 20 }}
            label="Prompt"
            value={imagePrompt}
            fullWidth
            multiline
            rows={3}
            onChange={(e: any) => setImagePrompt(e.target.value)}
          />
          {model === "dall-e-3" && (
            <React.Fragment>
              <FormControl fullWidth style={{ marginTop: 20 }}>
                <InputLabel>Quality</InputLabel>
                <Select
                  label="Quality"
                  value={imageQuality}
                  onChange={(e) => setImageQuality(e.target.value)}
                >
                  <MenuItem value="standard">Standard</MenuItem>
                  <MenuItem value="hd">HD</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth style={{ marginTop: 20 }}>
                <InputLabel>Style</InputLabel>
                <Select
                  label="Style"
                  value={imageStyle}
                  onChange={(e) => setImageStyle(e.target.value)}
                >
                  <MenuItem value="vivid">Vivid</MenuItem>
                  <MenuItem value="natural">Natural</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth style={{ marginTop: 20 }}>
                <InputLabel>Size</InputLabel>
                <Select
                  label="Size"
                  value={imageSize}
                  onChange={(e) => setImageSize(e.target.value)}
                >
                  <MenuItem value="1024x1024">1024x1024</MenuItem>
                  <MenuItem value="1792x1024">1792x1024 (wide)</MenuItem>
                  <MenuItem value="1024x1792">1024x1792 (tall)</MenuItem>
                </Select>
              </FormControl>
            </React.Fragment>
          )}
        </DialogContent>
        <DialogActions>
          <Chip label="5 Credits" style={{ marginRight: 5 }} />
          <Button onClick={createImage} startIcon={<GetLoadingIcon />}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="xl"
        open={imagePreviewOpen}
        onClose={() => setImagePreviewOpen(false)}
      >
        <DialogTitle>{selectedImage.prompt}</DialogTitle>
        <DialogContent>
          <img src={selectedImage.url} />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
