import Check from "@mui/icons-material/Check";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

export default () => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>
            <Typography variant="h6">Free</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h6">Individual</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h6">Business</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h6">Enterprise</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Access to top AI models</TableCell>
          <TableCell>Limited</TableCell>
          <TableCell>Standard</TableCell>
          <TableCell>Dedicated</TableCell>
          <TableCell>Unlimited</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Response times</TableCell>
          <TableCell>Limited on bandwidth & availability</TableCell>
          <TableCell>Fast</TableCell>
          <TableCell>Fast</TableCell>
          <TableCell>Fastest</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Questions</TableCell>
          <TableCell>50</TableCell>
          <TableCell>3000</TableCell>
          <TableCell>3000 / user</TableCell>
          <TableCell>Unlimited</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Context window</TableCell>
          <TableCell>16k</TableCell>
          <TableCell>200k</TableCell>
          <TableCell>200k</TableCell>
          <TableCell>200k</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Chat History</TableCell>
          <TableCell>Unlimited</TableCell>
          <TableCell>Unlimited</TableCell>
          <TableCell>Unlimited</TableCell>
          <TableCell>Unlimited</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Regular quality & speed improvements</TableCell>
          <TableCell>
            <Check />
          </TableCell>
          <TableCell>
            <Check />
          </TableCell>
          <TableCell>
            <Check />
          </TableCell>
          <TableCell>
            <Check />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Web Browsing</TableCell>
          <TableCell>
            <Check />
          </TableCell>
          <TableCell>
            <Check />
          </TableCell>
          <TableCell>
            <Check />
          </TableCell>
          <TableCell>
            <Check />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>OpenAI GPT-4o-mini</TableCell>
          <TableCell>
            <Check />
          </TableCell>
          <TableCell>
            <Check />
          </TableCell>
          <TableCell>
            <Check />
          </TableCell>
          <TableCell>
            <Check />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Google Gemini Text and Vision</TableCell>
          <TableCell></TableCell>
          <TableCell>
            <Check />
          </TableCell>
          <TableCell>
            <Check />
          </TableCell>
          <TableCell>
            <Check />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>OpenAI GPT-4 Text & Vision</TableCell>
          <TableCell></TableCell>
          <TableCell>
            <Check />
          </TableCell>
          <TableCell>
            <Check />
          </TableCell>
          <TableCell>
            <Check />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Anthropic Claude</TableCell>
          <TableCell></TableCell>
          <TableCell>
            <Check />
          </TableCell>
          <TableCell>
            <Check />
          </TableCell>
          <TableCell>
            <Check />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Generate AI images</TableCell>
          <TableCell></TableCell>
          <TableCell>
            <Check />
          </TableCell>
          <TableCell>
            <Check />
          </TableCell>
          <TableCell>
            <Check />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Share training documents with your workspace</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>
            <Check />
          </TableCell>
          <TableCell>
            <Check />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Dedicated AI Agents for Research Reports</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>
            <Check />
          </TableCell>
          <TableCell>
            <Check />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Chat in Slack</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>
            <Check />
          </TableCell>
          <TableCell>
            <Check />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Chat in Discord</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>
            <Check />
          </TableCell>
          <TableCell>
            <Check />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            AI Knowledge-Base (Google Docs, Confluence, Notion)
          </TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>
            <Check />
          </TableCell>
          <TableCell>
            <Check />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Dedicated workspace</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>
            <Check />
          </TableCell>
          <TableCell>
            <Check />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Share AI Employees</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>
            <Check />
          </TableCell>
          <TableCell>
            <Check />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Admin roles</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>
            <Check />
          </TableCell>
          <TableCell>
            <Check />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Chrome Extension</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>
            <Check />
          </TableCell>
          <TableCell>
            <Check />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Workflow Builder</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>
            <Check />
          </TableCell>
          <TableCell>
            <Check />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>On-premise deployment</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>
            <Check />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>SAML SSO</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>
            <Check />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Domain verification</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>
            <Check />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Priority support</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>
            <Check />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
