import { Firestore } from "@firebase/firestore";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { Box, Divider, Tooltip, Typography } from "@mui/material";
import axios from "axios";
import { User } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Blogs from "./Blogs";
import ChatLog from "./ChatLog";
import Collaborators from "./Collaborators";
import Documents from "./Documents";
import Employees from "./Employees";
import Images from "./Images";
import Integrations from "./Integrations";
import Projects from "./Projects";
import SearchBar from "./SearchBar";
import Workflows from "./Workflows";
import { Company, Employee } from "./types";
import { hostname, parseError, uniqueId } from "./utils";

export default ({
  db,
  mode,
  currentUser,
  id,
  token,
  getToken,
  eventListener,
  openDialog,
  subScreenName,
  instanceId,
}: {
  db: Firestore;
  mode: string;
  currentUser: any | User;
  id: string;
  token: any | string;
  getToken: Function;
  eventListener: Function;
  openDialog: Function;
  subScreenName: string | undefined;
  instanceId: string | undefined;
}) => {
  const [company, setCompany] = useState<Company>({
    uid: "",
    id: "",
    name: "",
    type: "",
    description: "",
    employees: [],
    website: "",
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [tags, setTags] = useState<Array<string>>(["read-all"]);
  const [currentPlan, setCurrentPlan] = useState<string>("free");
  const [newEmployee, setNewEmployee] = useState<Employee>({
    uid: "",
    id: uniqueId(),
    title: "",
    name: "",
    prompt: "",
    companyId: "",
    tags: [],
    picture: "",
  });
  const [modalLoading, setModalLoading] = useState(false);
  const [init, setInit] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState(subScreenName || "employees");

  useEffect(() => {
    setTab(subScreenName || "employees");
  }, [subScreenName]);

  const getCompany = async () => {
    const currentToken = await getToken();
    await axios
      .get(`${hostname}/companies/${id}`, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        setCompany(res.data.company);
        if (res.data.company.subscriptionType) {
          setCurrentPlan(res.data.company.subscriptionType);
        }
        if (res.data.company && res.data.company.name) {
          window.localStorage.setItem("activeCompanyName", res.data.company.name)
        }
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  const restoreCompanyName = () => {
    if (company.name) return;
    const activeName = window.localStorage.getItem("activeCompanyName");
    if (activeName) {
      setCompany({...company, name: activeName});
    }
  };

  const getData = async () => {
    restoreCompanyName();
    await Promise.all([getCompany()]);
    setLoading(false);
  };

  useEffect(() => {
    if (currentUser.uid) {
      setLoading(true);
      getData();
    }
  }, [currentUser.uid, id]);

  // if (loading) {
  //   return <LoadingScreen open={loading} />;
  // }

  return (
    <Box sx={{ pt: 2, p: 4 }}>
      <Typography
        variant="h4"
        style={{ marginTop: 10, fontFamily: '"Cairo", sans-serif' }}
      >
        {company.name}
        <Tooltip
          style={{ float: "right", marginTop: 10 }}
          title={`Add virtual employees to staff your company with all
          of the roles needed for a successful team. Tag employees with the same
          tag as company documents they should read.

          Add company documents to give your employees relevant
          information about your business. These documents will only be
          available to the employees that share a tag with the document.

          Employees have long-term memory within a single chat so they can
          retrieve context from previous messages and documents they are tagged
          with.
          `}
        >
          <InfoOutlined />
        </Tooltip>
      </Typography>
      <SearchBar
        companyId={id}
        eventListener={eventListener}
        token={token}
        getToken={getToken}
        openDialog={openDialog}
        navigate={navigate}
        setTab={setTab}
      />
      <Divider />
      <div hidden={tab !== "employees"}>
        <Employees
          companyId={id}
          getToken={getToken}
          openDialog={openDialog}
          currentUser={tab === "employees" ? currentUser : {}}
          company={company}
          eventListener={eventListener}
        />
      </div>
      <div hidden={tab !== "history"}>
        <ChatLog
          style={{ marginTop: 10, marginLeft: 10, marginRight: 10 }}
          companyId={id}
          getToken={getToken}
          currentUser={tab === "history" ? currentUser : {}}
          perPage={30}
          currentChatId={null}
          eventListener={eventListener}
        />
      </div>
      <div hidden={tab !== "documents"}>
        <Documents
          db={db}
          currentUser={tab === "documents" ? currentUser : {}}
          companyId={id}
          token={token}
          getToken={getToken}
          documentId={instanceId}
          eventListener={eventListener}
          openDialog={openDialog}
          setTab={setTab}
        />
      </div>
      {currentPlan !== "free" && (
        <div hidden={tab !== "projects"}>
          <Projects
            currentUser={tab === "projects" ? currentUser : {}}
            company={company}
            companyId={id}
            getToken={getToken}
            eventListener={eventListener}
          />
        </div>
      )}
      {currentPlan !== "free" && (
        <div hidden={tab !== "images"}>
          <Images
            currentUser={tab === "images" ? currentUser : {}}
            companyId={id}
            getToken={getToken}
            eventListener={eventListener}
          />
        </div>
      )}
      {(currentPlan === "Business" || currentPlan === "Enterprise") && (
        <div hidden={tab !== "blogs"}>
          <Blogs
            db={db}
            currentUser={tab === "blogs" ? currentUser : {}}
            companyId={id}
            token={token}
            getToken={getToken}
            documentId={instanceId}
            eventListener={eventListener}
            openDialog={openDialog}
            setTab={setTab}
          />
        </div>
      )}
      {(currentPlan === "Business" || currentPlan === "Enterprise") && (
        <div hidden={tab !== "integrations"}>
          <Integrations
            currentUser={tab === "integrations" ? currentUser : {}}
            companyId={id}
            db={db}
            token={token}
            getToken={getToken}
            eventListener={eventListener}
          />
        </div>
      )}
      {(currentPlan === "Business" || currentPlan === "Enterprise") && (
        <div hidden={tab !== "collaborators"}>
          <Collaborators
            openDialog={openDialog}
            company={company}
            companyId={id}
            getToken={getToken}
            currentUser={tab === "collaborators" ? currentUser : {}}
            eventListener={eventListener}
          />
        </div>
      )}
      {(currentPlan === "Business" || currentPlan === "Enterprise") && (
        <div hidden={tab !== "workflows"}>
          <Workflows
            mode={mode}
            currentUser={tab === "workflows" ? currentUser : {}}
            company={company}
            companyId={id}
            getToken={getToken}
            eventListener={eventListener}
            currentPlan={currentPlan}
          />
        </div>
      )}
    </Box>
  );
};
