import { ArticleOutlined, Folder } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Slider,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { writingModels } from "./constants";
import { hostname, parseError } from "./utils";

export default ({
  open,
  onClose,
  getToken,
  eventListener,
  companyId,
}: {
  open: boolean;
  onClose: () => void;
  getToken: Function;
  eventListener: Function;
  companyId: string;
}) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [blogArticleCount, setBlogArticleCount] = useState(8);
  const [blogStructure, setBlogStructure] = useState("category");
  const [dialogLoading, setDialogLoading] = useState(false);
  const [keywords, setKeywords] = useState<string[]>([]);
  const [response, setResponse] = useState<any>({ sections: [] });
  const [location, setLocation] = useState("");
  const [model, setModel] = useState(writingModels[0].name);
  const [useCompanyInformation, setUseCompanyInformation] = useState(false);
  const [useKnowledgeBase, setUseKnowledgeBase] = useState(true);
  const [useWebResearch, setUseWebResearch] = useState(true);

  const steps = ["General", "Structure", "Overview"];

  const handleNext = async () => {
    if (activeStep === 0) {
      await handleSubmitBlog();
    } else if (activeStep === 2) {
      await handleSubmitFinished();
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleSubmitFinished = async () => {
    console.log("Submitting for final");
  };

  const getArticleCount = () => {
    var articleCount = 0;
    response.pages.forEach((page: any) => {
      page.articles.forEach((article: any) => {
        articleCount += 1;
      });
      page.subSections.forEach((section: any) => {
        section.articles.forEach((article: any) => {
          articleCount += 1;
        });
      });
    });
    return articleCount;
  };

  const getTokenCount = () => {
    const articleCount = getArticleCount();
    return 5 * articleCount;
  };

  const getEstimatedTime = () => {
    const articleCount = getArticleCount();
    return `${3 * articleCount} minutes`;
  };

  const handleSubmitBlog = async () => {
    if (activeStep !== 0) {
      setActiveStep(activeStep + 1);
      return;
    }
    const currentToken = await getToken();
    setDialogLoading(true);
    axios
      .post(
        `${hostname}/blogs/structure`,
        {
          blogStructure,
          keywords,
          location,
          blogArticleCount,
          companyId,
          useCompanyInformation,
          model,
        },
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        setResponse(res.data);
        setActiveStep(activeStep + 1);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setDialogLoading(false);
      });
    console.log("Submit blog", blogArticleCount, blogStructure, keywords);
  };

  const handleFinishSubmit = async () => {
    const currentToken = await getToken();
    setDialogLoading(true);
    axios
      .post(
        `${hostname}/blogs`,
        {
          blog: response,
          companyId: companyId,
          model,
          useKnowledgeBase,
          useWebResearch,
        },
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      )
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setDialogLoading(false);
        onClose();
      });
  };

  const GetLoadingIcon = () => {
    return dialogLoading ? <CircularProgress size={20} /> : <div></div>;
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create Blog</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Choose options for a generating an entire blog.
        </DialogContentText>
        <Stepper activeStep={activeStep} sx={{ mt: 2 }}>
          <Step>
            <StepLabel>General</StepLabel>
          </Step>
          <Step>
            <StepLabel>Structure</StepLabel>
          </Step>
          <Step>
            <StepLabel>Overview</StepLabel>
          </Step>
        </Stepper>
        {activeStep === 0 && (
          <Box sx={{ mt: 4 }}>
            <Typography sx={{ mt: 2 }} gutterBottom>
              Number of articles: {blogArticleCount}
            </Typography>
            <Slider
              defaultValue={8}
              aria-label="Small"
              valueLabelDisplay="auto"
              onChange={(e: any) => setBlogArticleCount(e.target.value)}
            />
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel>Structure</InputLabel>
              <Select
                value={blogStructure}
                label="Structure"
                onChange={(e) => setBlogStructure(e.target.value)}
              >
                <MenuItem value={"category"}>Category &gt; Article</MenuItem>
                <MenuItem value={"location"}>Location &gt; Article</MenuItem>
                <MenuItem value={"location_service"}>
                  Location &gt; Service &gt; Article
                </MenuItem>
                <MenuItem value={"service_category"}>
                  Service &gt; Category &gt; Article
                </MenuItem>
              </Select>
            </FormControl>
            <Autocomplete
              multiple
              freeSolo
              filterSelectedOptions
              sx={{ mt: 2 }}
              options={keywords}
              getOptionLabel={(option: string) => option}
              onChange={(e, v) => setKeywords(v)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Keywords"
                  placeholder="Add SEO Keywords"
                  helperText="Click enter to add keyword to list"
                />
              )}
            />
            <TextField
              fullWidth
              sx={{ mt: 2 }}
              label="Location"
              value={location}
              helperText="Enter a location to better target local SEO keywords"
              onChange={(e: any) => setLocation(e.target.value)}
            />
            <FormControl fullWidth style={{ marginTop: 20 }}>
              <InputLabel>Model</InputLabel>
              <Select
                label="Model"
                value={model}
                onChange={(e) => setModel(e.target.value)}
              >
                {writingModels.map((model) => (
                  <MenuItem key={model.name} value={model.name}>
                    <Chip label={model.provider} style={{ marginRight: 5 }} />
                    {model.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControlLabel
              sx={{ width: "100%", mt: 1 }}
              control={<Switch />}
              value={useCompanyInformation}
              onChange={(e, v) => setUseCompanyInformation(v)}
              label="Use Company Description"
            />
            <FormControlLabel
              sx={{ width: "100%", mt: 1 }}
              control={<Switch />}
              value={useKnowledgeBase}
              onChange={(e, v) => setUseKnowledgeBase(v)}
              label="Use Knowledge Base"
            />
            <FormControlLabel
              sx={{ width: "100%", mt: 1 }}
              control={<Switch />}
              value={useWebResearch}
              onChange={(e, v) => setUseWebResearch(v)}
              label="Use Web Research"
            />
          </Box>
        )}
        {activeStep === 1 && (
          <Box sx={{ mt: 4 }}>
            <React.Fragment>
              <Typography variant="h4">{response.blogName}</Typography>
              <Divider sx={{ mt: 3, mb: 3 }} />
              <List>
                {response.pages.map((page: any) => (
                  <React.Fragment key={page.sectionName}>
                    <ListItem>
                      <ListItemIcon>
                        <Folder />
                      </ListItemIcon>
                      <ListItemText primary={page.sectionName} />
                    </ListItem>
                    <List sx={{ ml: 3 }}>
                      {page.articles.map((article: any) => (
                        <ListItem key={article.title}>
                          <ListItemIcon>
                            <ArticleOutlined />
                          </ListItemIcon>
                          <ListItemText
                            primary={article.title}
                            secondary={
                              <Box>
                                <Stack direction="row" spacing={1}>
                                  {article.keywords.map((keyword: string) => (
                                    <Chip label={keyword} />
                                  ))}
                                </Stack>
                                <Chip
                                  sx={{ mt: 1 }}
                                  label={`Estimated Reach: ${article.impressions}`}
                                />
                              </Box>
                            }
                          />
                        </ListItem>
                      ))}
                      {page.subSections.map((section: any) => (
                        <React.Fragment key={section.sectionName}>
                          <ListItem>
                            <ListItemIcon>
                              <Folder />
                            </ListItemIcon>
                            <ListItemText primary={section.sectionName} />
                          </ListItem>
                          <Box sx={{ ml: 3 }}>
                            <List>
                              {section.articles.map((article: any) => (
                                <ListItem key={article.title}>
                                  <ListItemIcon>
                                    <ArticleOutlined />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={article.title}
                                    secondary={
                                      <Box>
                                        <Stack direction="row" spacing={1}>
                                          {article.keywords.map(
                                            (keyword: string) => (
                                              <Chip
                                                key={keyword}
                                                label={keyword}
                                              />
                                            )
                                          )}
                                        </Stack>
                                        <Chip
                                          sx={{ mt: 1 }}
                                          label={`Estimated Reach: ${article.impressions}`}
                                        />
                                      </Box>
                                    }
                                  />
                                </ListItem>
                              ))}
                            </List>
                          </Box>
                        </React.Fragment>
                      ))}
                    </List>
                  </React.Fragment>
                ))}
              </List>
            </React.Fragment>
          </Box>
        )}
        {activeStep === 2 && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h4">Overview</Typography>
            <Typography variant="h5">{response.blogName}</Typography>
            <Divider sx={{ mt: 3, mb: 3 }} />
            <Typography>
              Total Articles: <Chip label={getArticleCount()} />
            </Typography>
            <Typography sx={{ mt: 1 }}>
              Expected Token Cost: <Chip label={getTokenCount()} />
            </Typography>
            <Typography sx={{ mt: 1 }}>
              Estimated Time: <Chip label={getEstimatedTime()} />
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose} sx={{ mt: 2 }}>
          Cancel
        </Button>
        <Button
          variant="text"
          onClick={
            activeStep === steps.length - 1
              ? handleFinishSubmit
              : handleSubmitBlog
          }
          sx={{ mt: 2 }}
          startIcon={<GetLoadingIcon />}
        >
          {activeStep === steps.length - 1 ? "Finish" : "Next"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
