import { Box, Container, Divider, Typography } from "@mui/material";
import React, { useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import PricingBlocks from "./PricingBlocks";
import PricingTable from "./PricingTable";

const PricingPage = ({
  setThemeForOnePage,
}: {
  setThemeForOnePage: (theme: string) => void;
}) => {
  setThemeForOnePage("dark");
  const [billingPeriod, setBillingPeriod] = useState("annual");
  return (
    <Box>
      <Header mode="dark" maxWidth="xl" />

      <Container maxWidth="xl">
        <Typography
          variant="h3"
          component="h1"
          style={{ marginTop: 50, marginLeft: 10 }}
        >
          Pricing
        </Typography>
        <PricingBlocks
          height={560}
          planSelector={false}
          selection="free"
          billingPeriod={billingPeriod}
          onBillingPeriod={(v: any) => setBillingPeriod(v)}
          onPlanSelection={() => {}}
        />

        <Divider style={{ marginBottom: 25, marginTop: 25 }} />
        <Divider style={{ marginBottom: 25 }} />
        <Divider style={{ marginBottom: 25 }} />

        <PricingTable />
      </Container>

      <Footer />
    </Box>
  );
};

export default PricingPage;
