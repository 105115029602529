import VerifiedIcon from "@mui/icons-material/Verified";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { User } from "firebase/auth";
import { Firestore } from "firebase/firestore";
import React, { useState } from "react";
import { CopyBlock, dracula } from "react-code-blocks";
import LoadingScreen from "./LoadingScreen";
import capture from "./capture";
import { Company } from "./types";
import { hostname, parseError } from "./utils";

export default function ({
  currentUser,
  companyId,
  db,
  token,
  getToken,
  eventListener,
}: {
  currentUser: User;
  companyId: string;
  db: Firestore;
  token: string;
  getToken: Function;
  eventListener: Function;
}) {
  const [init, setInit] = useState(false);
  const [integrationData, setIntegrationsData] = useState<Array<any>>([]);
  const [companies, setCompanies] = useState<Array<Company>>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState("");
  const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(false);
  const [loading, setLoading] = useState(true);
  const [googleDriveFolderId, setGoogleDriveFolderId] = useState("root");
  const [apiKey, setApiKey] = useState("");
  const [openApiKeyDialog, setOpenApiKeyDialog] = useState(false);
  const [destinationFolder, setDestinationFolder] = useState({});
  const upvote = (vote: string) => {
    // @ts-ignore
    if (window.posthog) {
      // @ts-ignore
      window.posthog.capture("Upvote", { vote: vote });
    }
  };

  const loginGoogle = useGoogleLogin({
    ux_mode: "popup",
    flow: "auth-code",
    scope:
      "https://www.googleapis.com/auth/documents.readonly https://www.googleapis.com/auth/drive.readonly",

    onSuccess: (tokenResponse) => {
      getToken().then((tok: string) => {
        axios
          .post(
            `${hostname}/integrations/google_docs/auth`,
            {
              ...tokenResponse,
              companyId: companyId,
              googleDriveFolderId,
            },
            {
              headers: {
                Authorization: `Bearer ${tok}`,
              },
            }
          )
          .then((res) => {
            capture("Installed integration", { type: "google docs" });
          })
          .catch((err) => {
            eventListener({
              type: "SET_ERROR",
              error: parseError(err),
            });
          })
          .finally(() => {
            getIntegrations();
          });
      });
    },
    onError: (err) => {
      eventListener({
        type: "SET_ERROR",
        error: parseError(err),
      });
    },
  });

  const installAtlassian = () => {
    axios
      .post(
        `${hostname}/integrations/atlassian/install`,
        {
          companyId: companyId,
          redirectUrl: window.location.href,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        capture("Installed integration", { type: "confluence" });
        window.location.href = res.data.redirect_url;
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  const installNotion = async () => {
    const currentToken = await getToken();
    axios
      .post(
        `${hostname}/integrations/notion/install`,
        {
          companyId: companyId,
          redirectUrl: window.location.href,
        },
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      )
      .then((res) => {
        capture("Installed integration", { type: "notion" });
        window.location.href = res.data.redirect_url;
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  const installDiscord = async () => {
    const currentToken = await getToken();
    axios
      .post(
        `${hostname}/integrations/discord/install`,
        {
          companyId: companyId,
          redirectUrl: window.location.href,
        },
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      )
      .then((res) => {
        capture("Installed integration", { type: "discord" });
        window.location.href = res.data.redirect_url;
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  const installZapier = async () => {
    const currentToken = await getToken();
    axios
      .put(
        `${hostname}/api_keys`,
        {
          name: selectedIntegration,
          companyId: companyId,
        },
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      )
      .then((res) => {
        setApiKey(res.data.apiKey);
        setOpenApiKeyDialog(true);
        capture("Installed integration", { type: "zapier" });
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  const installChrome = async () => {
    const currentToken = await getToken();
    axios
      .put(
        `${hostname}/api_keys`,
        {
          name: selectedIntegration,
          companyId: companyId,
        },
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      )
      .then((res) => {
        setApiKey(res.data.apiKey);
        setOpenApiKeyDialog(true);
        capture("Installed integration", { type: "chrome" });
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  const installSlack = () => {
    if (window.location.hostname === "localhost") {
      window.location.href = `http://127.0.0.1:5002/integrations/slack/install?state=${currentUser.uid}^${companyId}`;
    } else {
      window.location.href = `https://api.parallellabs.app/integrations/slack/install?state=${currentUser.uid}^${companyId}`;
    }
    capture("Installed integration", { type: "slack" });
  };

  const getCompanies = async () => {
    const currentToken = await getToken();
    await axios
      .get(`${hostname}/companies`, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        setCompanies(res.data.companies);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  const getIntegrations = async () => {
    const currentToken = await getToken();
    await axios
      .get(`${hostname}/integrations`, {
        params: {
          companyId,
        },
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        setIntegrationsData(res.data.integrations);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const removeIntegration = async (type: string, companyId: string) => {
    const currentToken = await getToken();
    axios
      .post(
        `${hostname}/integrations/remove`,
        { integrationName: type, companyId },
        { headers: { Authorization: `Bearer ${currentToken}` } }
      )
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        getIntegrations();
      });
  };

  const syncIntegration = async (type: string, companyId: string) => {
    axios
      .post(
        `${hostname}/integrations/${type}`,
        { companyId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        getIntegrations();
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  const integrationInstallStyle = (type: string) => {
    if (type === "google_docs") {
      return (
        // <Button
        //   style={{
        //     marginTop: 12,
        //     backgroundColor: "rgb(255, 255, 255)",
        //     height: 46,
        //     width: 210,
        //     display: "block",
        //     borderRadius: 5,
        //     border: "thin solid #888",
        //     boxShadow: "1px 1px 1px grey",
        //   }}
        //   onClick={(e) => {
        //     setSelectedIntegration("google_docs");
        //     setDialogOpen(true);
        //   }}
        // >
        //   <img
        //     style={{
        //       width: 21,
        //       height: 21,
        //       margin: 6,
        //       textAlign: "center",
        //       display: "block",
        //       float: "left",
        //       whiteSpace: "nowrap",
        //     }}
        //     src={require("./img/google-logo.svg")}
        //   />
        //   <p
        //     style={{
        //       color: "rgba(0, 0, 0, 0.54)",
        //       margin: 4,
        //       fontSize: "15px",
        //       fontWeight: 600,
        //       textTransform: "none",
        //     }}
        //   >
        //     Sign in with Google
        //   </p>
        // </Button>
        <Button
          type="button"
          variant="outlined"
          style={{ marginTop: 20 }}
          onClick={() => {
            setSelectedIntegration("google_docs");
            setDialogOpen(true);
          }}
        >
          Install
        </Button>
      );
    } else if (type === "slack") {
      return (
        <Button
          type="button"
          variant="outlined"
          style={{ marginTop: 20 }}
          onClick={() => {
            setSelectedIntegration("slack");
            setDialogOpen(true);
          }}
        >
          Install
        </Button>
      );
    } else if (type === "atlassian") {
      return (
        <Button
          type="button"
          variant="outlined"
          style={{ marginTop: 20 }}
          onClick={() => {
            setSelectedIntegration("atlassian");
            setDialogOpen(true);
          }}
        >
          Install
        </Button>
      );
    } else if (type === "notion") {
      return (
        <Button
          type="button"
          variant="outlined"
          style={{ marginTop: 20 }}
          onClick={() => {
            setSelectedIntegration("notion");
            setDialogOpen(true);
          }}
        >
          Install
        </Button>
      );
    } else if (type === "discord") {
      return (
        <Button
          type="button"
          variant="outlined"
          style={{ marginTop: 20 }}
          onClick={() => {
            setSelectedIntegration("discord");
            setDialogOpen(true);
          }}
        >
          Install
        </Button>
      );
    } else if (type === "zapier") {
      return (
        <Button
          type="button"
          variant="outlined"
          style={{ marginTop: 20 }}
          onClick={() => {
            setSelectedIntegration("zapier");
            setDialogOpen(true);
          }}
        >
          Install
        </Button>
      );
    } else if (type === "chrome") {
      return (
        <React.Fragment>
          <Button
            type="button"
            variant="outlined"
            component={Link}
            target="_blank"
            rel="noopener noreferrer"
            href="https://chromewebstore.google.com/detail/parallel-ai/ghfjnjjpepjibmkegmafnlammfopdifk"
            style={{ marginTop: 20, marginRight: 10 }}
          >
            Install
          </Button>
          <Button
            type="button"
            variant="outlined"
            style={{ marginTop: 20 }}
            onClick={() => {
              setSelectedIntegration("chrome");
              setDialogOpen(true);
            }}
          >
            Get API Key
          </Button>
        </React.Fragment>
      );
    } else {
      return (
        <Button type="button" variant="outlined" style={{ marginTop: 20 }}>
          Coming soon
        </Button>
      );
    }
  };

  if (currentUser && currentUser.uid && !init) {
    setInit(true);
    getIntegrations();
    getCompanies();
  }

  if (loading) {
    return <LoadingScreen open={true} />;
  }

  const handleClose = (e: any) => {
    setDialogOpen(false);
  };

  const installIntegration = (e: any) => {
    e.preventDefault();
    setDialogOpen(false);
    if (selectedIntegration === "google_docs") {
      loginGoogle();
    } else if (selectedIntegration === "slack") {
      installSlack();
    } else if (selectedIntegration === "atlassian") {
      installAtlassian();
    } else if (selectedIntegration === "notion") {
      installNotion();
    } else if (selectedIntegration === "discord") {
      installDiscord();
    } else if (selectedIntegration === "zapier") {
      installZapier();
    } else if (selectedIntegration === "chrome") {
      installChrome();
    }
  };

  return (
    <Box>
      <Dialog
        open={openApiKeyDialog}
        onClose={() => setOpenApiKeyDialog(false)}
        fullWidth={true}
      >
        <DialogTitle>Parallel AI API Key</DialogTitle>
        <DialogContent>
          <Typography>
            Use the following API key for authenticating with Parallel AI.
          </Typography>
          <div
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <CopyBlock
              language={"javascript"}
              text={apiKey}
              wrapLines
              codeBlock
              showLineNumbers={false}
              theme={dracula}
            />
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={dialogOpen} onClose={handleClose} fullWidth={true}>
        <DialogTitle>Install Integration</DialogTitle>
        <DialogContent>
          <form onSubmit={installIntegration} style={{ padding: 10 }}>
            {selectedIntegration === "google_docs" && (
              <FormControl fullWidth>
                <TextField
                  style={{ marginTop: 20 }}
                  fullWidth
                  value={googleDriveFolderId}
                  label="Google Drive Folder ID"
                  onChange={(e) => setGoogleDriveFolderId(e.target.value)}
                />
              </FormControl>
            )}
            <div style={{ textAlign: "center" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    required
                    checked={privacyPolicyChecked}
                    onChange={() =>
                      setPrivacyPolicyChecked(!privacyPolicyChecked)
                    }
                    name="privacyPolicy"
                    color="primary"
                  />
                }
                label={
                  <p>
                    I agree to the <Link href="/terms">Terms of Service</Link>{" "}
                    and <Link href="/privacy">Privacy Policy</Link>
                  </p>
                }
              />
            </div>
            <Button type="submit" fullWidth>
              Install
            </Button>
          </form>
        </DialogContent>
      </Dialog>
      <Grid container style={{ marginTop: 30 }} spacing={2}>
        <Grid item xs={12} md={4}>
          <Paper style={{ padding: 10 }} variant="outlined">
            <Box style={{ height: 60 }}>
              <img
                style={{ width: 88, marginTop: 15, marginLeft: 6 }}
                src={require("./img/slack.png")}
              ></img>
            </Box>
            <Typography variant="h6" style={{ marginLeft: 5 }}>
              Slack
            </Typography>
            <Typography variant="body2" style={{ marginLeft: 5 }}>
              <VerifiedIcon style={{ marginRight: 6, fontSize: 12 }} />
              Built by Parallel AI
            </Typography>
            {integrationInstallStyle("slack")}
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper style={{ padding: 10, minHeight: 160 }} variant="outlined">
            <Box style={{ height: 60 }}>
              <img
                style={{ width: 40, marginTop: 8, marginLeft: 6 }}
                src={require("./img/discord.svg")}
              ></img>
            </Box>
            <Typography variant="h6" style={{ marginLeft: 5 }}>
              Discord
            </Typography>
            <Typography variant="body2" style={{ marginLeft: 5 }}>
              <VerifiedIcon style={{ marginRight: 6, fontSize: 12 }} />
              Built by Parallel AI
            </Typography>
            {integrationInstallStyle("discord")}
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper style={{ padding: 10, minHeight: 160 }} variant="outlined">
            <Box style={{ height: 60 }}>
              <img
                style={{ width: 40, marginLeft: 8, marginTop: 5 }}
                src={require("./img/gdrive.png")}
              ></img>
            </Box>
            <Typography variant="h6" style={{ marginLeft: 5 }}>
              Google Drive
            </Typography>
            <Typography variant="body2" style={{ marginLeft: 5 }}>
              <VerifiedIcon style={{ marginRight: 6, fontSize: 12 }} />
              Built by Parallel AI
            </Typography>
            {integrationInstallStyle("google_docs")}
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper style={{ padding: 10, minHeight: 160 }} variant="outlined">
            <Box style={{ height: 60 }}>
              <img
                style={{ width: 51 }}
                src={require("./img/confluence.svg")}
              ></img>
            </Box>
            <Typography variant="h6" style={{ marginLeft: 5 }}>
              Confluence
            </Typography>
            <Typography variant="body2" style={{ marginLeft: 5 }}>
              <VerifiedIcon style={{ marginRight: 6, fontSize: 12 }} />
              Built by Parallel AI
            </Typography>
            {integrationInstallStyle("atlassian")}
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper style={{ padding: 10, minHeight: 160 }} variant="outlined">
            <Box style={{ height: 60 }}>
              <img
                style={{ width: 39, marginTop: 8, marginLeft: 6 }}
                src={require("./img/notion.svg")}
              ></img>
            </Box>
            <Typography variant="h6" style={{ marginLeft: 5 }}>
              Notion
            </Typography>
            <Typography variant="body2" style={{ marginLeft: 5 }}>
              <VerifiedIcon style={{ marginRight: 6, fontSize: 12 }} />
              Built by Parallel AI
            </Typography>
            {integrationInstallStyle("notion")}
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper style={{ padding: 10, minHeight: 165 }} variant="outlined">
            <Box style={{ height: 60 }}>
              <img
                style={{ width: 76, marginTop: 8, marginLeft: 6 }}
                src={require("./img/zapier.svg")}
              ></img>
            </Box>
            <Typography variant="h6" style={{ marginLeft: 5 }}>
              Zapier
            </Typography>
            <Typography variant="body2" style={{ marginLeft: 5 }}>
              <VerifiedIcon style={{ marginRight: 6, fontSize: 12 }} />
              Built by Parallel AI
            </Typography>
            {integrationInstallStyle("zapier")}
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper style={{ padding: 10, minHeight: 165 }} variant="outlined">
            <Box style={{ height: 60 }}>
              <img
                style={{ width: 38, marginTop: 8, marginLeft: 6 }}
                src={require("./img/chrome.svg")}
              ></img>
            </Box>
            <Typography variant="h6" style={{ marginLeft: 5 }}>
              Chrome Extension (Beta)
            </Typography>
            <Typography variant="body2" style={{ marginLeft: 5 }}>
              <VerifiedIcon style={{ marginRight: 6, fontSize: 12 }} />
              Built by Parallel AI
            </Typography>
            {integrationInstallStyle("chrome")}
          </Paper>
        </Grid>
      </Grid>
      {integrationData.length !== 0 && (
        <div>
          <Typography variant="h5" style={{ marginTop: 40 }}>
            Installed
          </Typography>
          <TableContainer
            component={Paper}
            variant="outlined"
            style={{ marginTop: 20, marginBottom: 40 }}
          >
            <Table sx={{ minWidth: 500 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell align="right">Sync Status</TableCell>
                  <TableCell align="right">Last Sync</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {integrationData.map((row) => (
                  <TableRow key={row.name + row.companyId} onClick={() => {}}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell align="right">
                      {row.status.substring(0, 1).toUpperCase() +
                        row.status.substring(1)}
                    </TableCell>
                    <TableCell align="right">{row.lastSync}</TableCell>
                    <TableCell align="right">
                      <div>
                        {["atlassian", "notion", "google_docs"].includes(
                          row.type
                        ) && (
                          <Button
                            variant="outlined"
                            onClick={() =>
                              syncIntegration(row.type, row.companyId)
                            }
                          >
                            Sync
                          </Button>
                        )}
                        <Button
                          type="button"
                          variant="outlined"
                          style={{ marginLeft: 10 }}
                          color="error"
                          onClick={() =>
                            removeIntegration(row.type, row.companyId)
                          }
                        >
                          Remove
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </Box>
  );
}
