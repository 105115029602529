import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { PaletteMode } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { initializeApp } from "firebase/app";
import "firebase/auth";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import "firebaseui/dist/firebaseui.css";
import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import Blog from "./src/Blog";
import BlogPost from "./src/BlogPost";
import CustumSolutions from "./src/CustumSolutions";
import Dashboard from "./src/Dashboard";
import HomePage from "./src/HomePage";
import Login from "./src/Login";
import LoginSso from "./src/LoginSso";
import Pricing from "./src/Pricing";
import Privacy from "./src/Privacy";
import Terms from "./src/Terms";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDLz47VZICvgh831kAsBxevMyI5kMz3C0c",
  authDomain: "parallel-ai.firebaseapp.com",
  projectId: "ai-employee",
  storageBucket: "ai-employee.appspot.com",
  messagingSenderId: "235414097748",
  appId: "1:235414097748:web:1623ff407190b1df36c2c1",
};

const getTheme = (mode: PaletteMode) => {
  const primaryColor = mode === "dark" ? "#A3F7BF" : "#29A19C";
  const secondaryColor = mode === "dark" ? "#29A19C" : "#A3F7BF";
  const contrastColor = mode === "dark" ? "white" : "black";
  const theme = {
    palette: {
      mode: mode,
      primary: {
        main: primaryColor,
        contrastText: contrastColor,
      },
      secondary: {
        main: secondaryColor,
      },
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: mode === "dark" ? "rgb(26, 44, 53)" : "#fff",
          },
        },
      },
    },
  };
  if (mode === "dark") {
    // @ts-ignore
    theme.palette.background = {
      default: "rgb(26, 44, 53)",
      paper: "#393E46",
    };
  }
  return createTheme(theme);
};

export default function App() {
  const [mode, setMode] = useState("system");
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  var theme = null;
  if (mode === "system") {
    theme = getTheme(prefersDarkMode ? "dark" : "light");
    setMode(prefersDarkMode ? "dark" : "light");
  } else {
    theme = getTheme(mode as PaletteMode);
  }

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const db = getFirestore(app);

  const restoreToken = () => {
    const mode = window.localStorage.getItem("mode");
    if (mode) {
      setMode(mode);
    }
  };

  const setThemeMode = (mode: string) => {
    window.localStorage.setItem("mode", mode);
    setMode(mode);
  };

  const setThemeForOnePage = (mode: string) => {
    setMode(mode);
  };

  useEffect(() => {
    restoreToken();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GoogleOAuthProvider clientId="235414097748-5fpehtnvd7nuhud1vm5atupldi9945tv.apps.googleusercontent.com">
        <ParallaxProvider>
          <CssBaseline />
          <Router>
            <Routes>
              <Route
                path="/"
                element={<HomePage setThemeForOnePage={setThemeForOnePage} />}
              />
              <Route
                path="/custom-solution"
                element={
                  <CustumSolutions
                    mode={mode}
                    setThemeForOnePage={setThemeForOnePage}
                  />
                }
              />
              <Route
                path="/pricing"
                element={<Pricing setThemeForOnePage={setThemeForOnePage} />}
              />
              <Route
                path="/login"
                element={<Login auth={auth} mode={mode} isLoginParam={true} />}
              />
              <Route
                path="/signup"
                element={<Login auth={auth} mode={mode} isLoginParam={false} />}
              />
              <Route
                path="/login/sso"
                element={<LoginSso auth={auth} mode={mode} />}
              />
              <Route
                path="/blog"
                element={<Blog setThemeForOnePage={setThemeForOnePage} />}
              />
              <Route
                path="/blog/:name"
                element={<BlogPost setThemeForOnePage={setThemeForOnePage} />}
              />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route
                path="/dashboard/:screenName"
                element={
                  <Dashboard
                    auth={auth}
                    db={db}
                    setThemeMode={setThemeMode}
                    mode={mode}
                  />
                }
              />
              <Route
                path="/dashboard/:screenName/:companyId"
                element={
                  <Dashboard
                    auth={auth}
                    db={db}
                    setThemeMode={setThemeMode}
                    mode={mode}
                  />
                }
              />
              <Route
                path="/dashboard/:screenName/:companyId/:subScreenName"
                element={
                  <Dashboard
                    auth={auth}
                    db={db}
                    setThemeMode={setThemeMode}
                    mode={mode}
                  />
                }
              />
              <Route
                path="/dashboard/:screenName/:companyId/:subScreenName/:instanceId"
                element={
                  <Dashboard
                    auth={auth}
                    db={db}
                    setThemeMode={setThemeMode}
                    mode={mode}
                  />
                }
              />
            </Routes>
          </Router>
        </ParallaxProvider>
      </GoogleOAuthProvider>
    </ThemeProvider>
  );
}
