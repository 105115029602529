import { Firestore } from "@firebase/firestore";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { User } from "firebase/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "./LoadingScreen";
import ChatApi from "./chatApi";
import { Company } from "./types";
import { parseError, uniqueId } from "./utils";

import BusinessIcon from "@mui/icons-material/BusinessOutlined";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import axios from "axios";
import capture from "./capture";
import { hostname } from "./utils";

const Companies = ({
  db,
  currentUser,
  token,
  getToken,
  eventListener,
  openDialog,
}: {
  db: Firestore;
  currentUser: any | User;
  token: any | string;
  getToken: Function;
  eventListener: Function;
  openDialog: Function;
}) => {
  const navigate = useNavigate();
  const [companies, setCompanies] = useState<Array<Company>>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [newCompany, setNewCompany] = useState<Company>({
    uid: "",
    id: uniqueId(),
    name: "",
    type: "",
    description: "",
    website: "",
    employees: [],
  });
  const [init, setInit] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [statusText, setStatusText] = useState("");
  const [loading, setLoading] = useState(true);
  const [descLoading, setDescLoading] = useState(false);
  const chatApi = new ChatApi();
  chatApi.init(eventListener, getToken);

  const createCompany = async (retry: number | undefined = 0) => {
    setModalLoading(true);
    try {
      await saveCompany(newCompany);
      capture("Create company", {});
      await chatApi.createEmployees(
        newCompany,
        9,
        1,
        null,
        (newStatus: string) => setStatusText(newStatus)
      );
    } catch {
      capture("Create company failed", {});
      if (retry < 3) {
        createCompany(retry + 1);
      }
    }
    capture("Create company sucess", {});
    setNewCompany({
      uid: "",
      id: uniqueId(),
      name: "",
      type: "",
      description: "",
      website: "",
      employees: [],
    });
    await getCompanies();
    setModalOpen(false);
    setModalLoading(false);
    setStatusText("");
  };

  const saveCompany = async (company: Company) => {
    const currentToken = await getToken();
    await axios
      .post(`${hostname}/companies`, company, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  const editCompany = async () => {
    setModalLoading(true);
    saveCompany(newCompany);
    setNewCompany({
      uid: "",
      id: uniqueId(),
      name: "",
      type: "",
      description: "",
      website: "",
      employees: [],
    });
    await getCompanies();
    setEditMode(false);
    setModalOpen(false);
    setModalLoading(false);
  };

  const deleteCompany = async (id: string, name: string) => {
    const currentToken = await getToken();
    openDialog(
      "Delete Company",
      `Are you sure you want to delete company ${name}?`,
      "Confirm",
      () => () => {
        setLoading(true);
        axios
          .delete(`${hostname}/companies/${id}`, {
            headers: {
              Authorization: `Bearer ${currentToken}`,
            },
          })
          .catch((err) => {
            eventListener({
              type: "SET_ERROR",
              error: parseError(err),
            });
          })
          .finally(() => {
            getCompanies();
          });
      }
    );
  };

  const getCompanies = async () => {
    setLoading(true);
    const currentToken = await getToken();
    await axios
      .get(`${hostname}/companies`, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        setCompanies(res.data.companies);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
    setLoading(false);
  };

  const generateDescription = async () => {
    setDescLoading(true);
    try {
      const prompt = `
    Please write a company description in under 100 words for the following company name and type.

    Name: ${newCompany.name}
    Type: ${newCompany.type}
    `;
      const description = await chatApi.syncCompletion(prompt);
      setNewCompany({ ...newCompany, description: description });
    } catch (err) {
      eventListener({
        type: "SET_ERROR",
        error: parseError(err),
      });
    }
    setDescLoading(false);
  };

  if (currentUser.uid && !init) {
    setInit(true);
    getCompanies();
  }

  if (loading) {
    return <LoadingScreen open={loading} />;
  }

  return (
    <Box sx={{ p: 4 }}>
      <Typography
        style={{ marginTop: 30, fontFamily: '"Cairo", sans-serif' }}
        variant="h4"
      >
        Companies
        <Tooltip
          style={{ float: "right", marginTop: 10 }}
          title={`Virtual companies are used to pre-prompt your virtual employees
                      about your business. The AI will be trained as a employee in this
                      virtual company and return answers relevant to the description and
                      industry. Each company will be self contained with no information
                      shared between them. This can be a shadow company of your existing
                      business or a business you want to start.
                      To get started, create a company, and start chatting with your
                      pre-generated employees.`}
        >
          <InfoOutlined />
        </Tooltip>
      </Typography>
      <Button
        style={{ marginBottom: 30, marginTop: 20 }}
        onClick={() => {
          setNewCompany({
            uid: "",
            id: uniqueId(),
            name: "",
            type: "",
            description: "",
            website: "",
            employees: [],
          });
          setEditMode(false);
          setModalOpen(true);
          setStatusText("");
        }}
      >
        <BusinessIcon style={{ marginRight: 5 }} /> Create Company
      </Button>
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        maxWidth={"md"}
        fullWidth={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogContent>
          {!editMode && (
            <div>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Create New Company
              </Typography>
              <Typography variant="body1" style={{ marginTop: 10 }}>
                Provide a name, type and description for the new company. This
                information will be used to generate your AI employees and
                pre-prompt them for company relevant answers.
              </Typography>
            </div>
          )}
          {editMode && (
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ marginTop: 10 }}
            >
              Edit Company
            </Typography>
          )}
          <TextField
            style={{ marginTop: 20 }}
            fullWidth
            value={newCompany.name}
            label="Name"
            onChange={(e) =>
              setNewCompany({ ...newCompany, name: e.target.value })
            }
          />
          <TextField
            style={{ marginTop: 20 }}
            value={newCompany.type}
            fullWidth
            onChange={(e) =>
              setNewCompany({ ...newCompany, type: e.target.value })
            }
            label="Company Type"
          />
          <TextField
            style={{ marginTop: 20 }}
            value={newCompany.website}
            fullWidth
            onChange={(e) =>
              setNewCompany({ ...newCompany, website: e.target.value })
            }
            label="Website (Optional)"
          />
          <TextField
            style={{
              marginTop: 20,
            }}
            value={newCompany.description}
            multiline
            minRows={5}
            maxRows={8}
            fullWidth
            onChange={(e) =>
              setNewCompany({ ...newCompany, description: e.target.value })
            }
            label="Description"
          />
          {!editMode && (
            <Button
              style={{ marginTop: 5 }}
              onClick={() => generateDescription()}
            >
              Generate Description
              {descLoading && (
                <CircularProgress
                  style={{ marginLeft: 10, float: "right" }}
                  color="inherit"
                  size={20}
                />
              )}
            </Button>
          )}
          <div style={{ marginTop: 20, marginBottom: 20 }}>{statusText}</div>
          {!editMode && (
            <Button
              variant="outlined"
              style={{ marginTop: 10 }}
              onClick={() => createCompany()}
            >
              Create
              {modalLoading && (
                <CircularProgress
                  style={{ marginLeft: 10, float: "right" }}
                  color="inherit"
                  size={20}
                />
              )}
            </Button>
          )}
          {editMode && (
            <Button
              variant="outlined"
              style={{ marginTop: 10 }}
              onClick={() => editCompany()}
            >
              Save
              {modalLoading && (
                <CircularProgress
                  style={{ marginLeft: 10, float: "right" }}
                  color="inherit"
                  size={20}
                />
              )}
            </Button>
          )}
        </DialogContent>
      </Dialog>
      <Grid container spacing={2} style={{ marginBottom: 20 }}>
        {companies.map((company, index) => (
          <Grid key={company.id} item md={4} xs={12}>
            <Paper
              variant="outlined"
              style={{ padding: 10, cursor: "pointer" }}
              onClick={() => {
                window.location.href = "/dashboard/companies/" + company.id;
              }}
            >
              <Typography
                variant="h6"
                style={{
                  overflow: "hidden",
                  height: 35,
                  display: "-webkit-box",
                  WebkitLineClamp: 1,
                  lineClamp: 1,
                  WebkitBoxOrient: "vertical",
                  fontFamily: '"Cairo", sans-serif',
                }}
              >
                {company.name} {company.name === "ACME LLC" ? "(Sample)" : ""}
              </Typography>
              <Typography
                variant="body1"
                style={{
                  overflow: "hidden",
                  height: 24,
                  display: "-webkit-box",
                  WebkitLineClamp: 1,
                  lineClamp: 1,
                  WebkitBoxOrient: "vertical",
                }}
              >
                {company.type}
              </Typography>
              <Divider style={{ marginTop: 10 }} />
              <Typography
                variant="body2"
                style={{
                  overflow: "hidden",
                  height: 80,
                  marginTop: 10,
                  marginBottom: 5,
                  display: "-webkit-box",
                  WebkitLineClamp: 4,
                  lineClamp: 4,
                  WebkitBoxOrient: "vertical",
                }}
              >
                {company.description}
              </Typography>
              <Button
                onClick={(e: any) => {
                  e.stopPropagation();
                  window.location.href = "/dashboard/companies/" + company.id;
                  //navigate(company.id);
                }}
              >
                Open
              </Button>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setNewCompany(company);
                  setEditMode(true);
                  setModalOpen(true);
                  setStatusText("");
                }}
              >
                Edit
              </Button>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  deleteCompany(company.id, company.name);
                }}
              >
                Delete
              </Button>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Companies;
