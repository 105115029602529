import { Button, CircularProgress } from "@mui/material";
import React from "react";

const LoadingButton = ({
  children,
  loading = false,
  variant = "contained",
  onClick,
  ...props
}) => {
  return (
    <Button
      variant={variant}
      onClick={onClick}
      disabled={loading}
      startIcon={
        loading ? <CircularProgress size={20} color="inherit" /> : <></>
      }
      {...props}
    >
      {children}
    </Button>
  );
};

export default LoadingButton;
