import ContentCopy from "@mui/icons-material/ContentCopy";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React from "react";
import ReactMarkdown from "react-markdown";
import { countWords } from "./utils";

const ReportViewer = ({
  open,
  id,
  title,
  content,
  onClose,
  downloadPdf,
  eventListener,
}: {
  open: boolean;
  id: string;
  title: string;
  content: string;
  onClose: () => void;
  downloadPdf: (id: string) => void;
  eventListener: Function;
}) => {
  const copyContent = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      eventListener({
        type: "SET_INFO",
        info: "Content copied to clipboard",
      });
    } catch (err) {
      eventListener({
        type: "SET_ERROR",
        error: "Error copying to clipboard",
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogTitle>
        <IconButton onClick={() => downloadPdf(id)}>
          <PictureAsPdf />
        </IconButton>
        <IconButton onClick={() => copyContent(content)}>
          <ContentCopy />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ReactMarkdown children={content} />
        <DialogContentText>{countWords(content)} words</DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default ReportViewer;
