import { Firestore } from "@firebase/firestore";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolderOutlined";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Description from "@mui/icons-material/Description";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PostAddIcon from "@mui/icons-material/PostAddOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";
import SourceIcon from "@mui/icons-material/SourceOutlined";
import SyncIcon from "@mui/icons-material/Sync";
import TravelExploreIcon from "@mui/icons-material/TravelExploreOutlined";
import UploadFileIcon from "@mui/icons-material/UploadFileOutlined";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  LinearProgress,
  Link,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { User } from "firebase/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import FolderSelector from "./FolderSelector";
import LoadingScreen from "./LoadingScreen";
import SelectEmployeeDialog from "./SelectEmployee";
import { Company, Document } from "./types";
import { hostname, parseError, uniqueId } from "./utils";

export default ({
  db,
  currentUser,
  companyId,
  token,
  getToken,
  eventListener,
  openDialog,
  documentId,
  setTab,
}: {
  db: Firestore;
  currentUser: any | User;
  companyId: string;
  token: any | string;
  getToken: Function;
  eventListener: Function;
  openDialog: Function;
  documentId: any | string;
  setTab: Function;
}) => {
  const getNewDocument = () => {
    return {
      id: uniqueId(),
      companyId: companyId,
      name: "",
      type: "txt",
      content: "",
      tags: [],
      tokens: 0,
      created: "",
      updated: "",
      subDocuments: [],
      path: "",
    };
  };
  const [init, setInit] = useState(false);
  const [documents, setDocuments] = useState<Array<Document>>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [tags, setTags] = useState<Array<string>>([]);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [uploadDocumentMode, setUploadDocumentMode] = useState(false);
  const [newDocument, setNewDocument] = useState<Document>(getNewDocument());
  const [loading, setLoading] = useState(true);
  const [fullWidthDialog, setFullWidthDialog] = useState(true);
  const [company, setCompany] = useState<Company>({
    uid: "",
    id: "",
    name: "",
    type: "",
    description: "",
    employees: [],
    website: "",
  });
  const navigate = useNavigate();
  const [path, setPath] = useState("/");
  const [createFolderOpen, setCreateFolderOpen] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [crawlWebsiteOpen, setCrawlWebsiteOpen] = useState(false);
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [refreshing, setRefreshing] = useState(false);
  const [documentContentLoading, setDocumentContentLoading] = useState(false);
  const [selectEmployeesOpen, setSelectEmployeesOpen] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [openButton, setOpenButton] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [folderSelectorOpen, setFolderSelectorOpen] = useState(false);
  const [moveFileId, setMoveFileId] = useState("");

  async function getData(folderPath: string) {
    setInit(true);
    setLoading(true);
    const currentToken = await getToken();
    axios
      .get(`${hostname}/companies/${companyId}`, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        setCompany(res.data.company);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
    axios
      .get(`${hostname}/companies/${companyId}/employees`, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        setEmployees(res.data.employees);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
    await axios
      .get(`${hostname}/documents`, {
        headers: { Authorization: `Bearer ${currentToken}` },
        params: { path: folderPath, companyId: companyId },
      })
      .then((res) => {
        var documentList = res.data;
        const tgs: Set<string> = new Set();
        if (folderPath === "/") {
          documentList = [
            {
              id: "shared",
              name: "Shared",
              type: "folder",
              tags: [],
            },
            ...documentList,
          ];
        }
        setDocuments(documentList);
        documentList.forEach((x: Document) => {
          x.tags.forEach((y: string) => tgs.add(y));
        });
        setTags(Array.from(tgs.values()));
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
    setLoading(false);
  }

  const refreshData = async (folderPath: string) => {
    setRefreshing(true);
    const currentToken = await getToken();
    axios
      .get(`${hostname}/documents`, {
        headers: { Authorization: `Bearer ${currentToken}` },
        params: { path: folderPath, companyId: companyId },
      })
      .then((res) => {
        var documentList = res.data;
        const tgs: Set<string> = new Set();
        if (folderPath === "/") {
          documentList = [
            {
              id: "shared",
              name: "Shared",
              type: "folder",
              tags: [],
            },
            ...documentList,
          ];
        }
        setDocuments(documentList);
        documentList.forEach((x: Document) => {
          x.tags.forEach((y: string) => tgs.add(y));
        });
        setTags(Array.from(tgs.values()));
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setRefreshing(false);
      });
  };

  const getDocumentContents = async (doc: Document) => {
    setDocumentContentLoading(true);
    const currentToken = await getToken();
    axios
      .get(`${hostname}/documents/${companyId}/${doc.id}`, {
        headers: { Authorization: `Bearer ${currentToken}` },
      })
      .then((res) => {
        setNewDocument({ ...doc, content: res.data.content });
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setDocumentContentLoading(false);
      });
  };

  const chatWithDocument = async (employee: any) => {
    const currentToken = await getToken();
    const chatId = uniqueId();
    await axios
      .post(
        `${hostname}/chats`,
        {
          ...employee,
          id: chatId,
          companyId: companyId,
          ts: new Date().getTime(),
          employeeId: employee.id,
          documentScope: newDocument.id,
          documentPath: newDocument.path,
          folderPath: newDocument.path,
        },
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      )
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
    navigate("/dashboard/companies/" + companyId + "/chats/" + chatId);
  };

  const openDocumentId = async (dId: string) => {
    const currentToken = await getToken();
    axios
      .get(`${hostname}/documents/${companyId}/document/${dId}`, {
        headers: { Authorization: `Bearer ${currentToken}` },
      })
      .then((res) => {
        setNewDocument({ ...res.data, content: "Loading..." });
        setUploadDocumentMode(false);
        setFullWidthDialog(true);
        setEditMode(true);
        setModalOpen(true);
        getDocumentContents(res.data);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setDocumentContentLoading(false);
      });
  };

  if (currentUser.uid && !init) {
    getData(path);
    if (documentId) {
      openDocumentId(documentId);
    }
  }

  const uploadDocumentFile = async (
    file: File | undefined,
    content: string | undefined,
    name: string | undefined
  ) => {
    setModalLoading(true);
    setModalOpen(true);
    const formData = new FormData();
    if (file) {
      formData.append("file", file);
    } else if (content && name) {
      formData.append("content", content);
      formData.append("name", name);
    }

    formData.append("tags", newDocument.tags.join(","));
    formData.append("companyId", companyId);
    formData.append("path", path);
    const currentToken = await getToken();
    await axios
      .post(`${hostname}/documents/upload`, formData, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
    setModalLoading(false);
    setModalOpen(false);
    setSelectedFile(undefined);
    refreshData(path);
  };

  const deleteDocument = async (d: Document) => {
    setRefreshing(true);
    const currentToken = await getToken();
    await axios
      .delete(`${hostname}/documents/${d.id}`, {
        headers: { Authorization: `Bearer ${currentToken}` },
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
    const newDocuments = documents.filter((x) => x.id !== d.id);
    setDocuments(newDocuments);
    setRefreshing(false);
  };

  const editDocument = async (d: Document) => {
    setModalLoading(true);
    const currentToken = await getToken();
    await axios
      .post(`${hostname}/documents`, d, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
    const newDocuments = documents.map((x) => {
      if (x.id === d.id) {
        return d;
      }
      return x;
    });
    setDocuments(newDocuments);
    setModalOpen(false);
    setEditMode(false);
    setNewDocument(getNewDocument());
    setModalLoading(false);
  };

  const crawlWebsite = async () => {
    setModalLoading(true);
    const currentToken = await getToken();
    axios
      .post(
        `${hostname}/websites/import`,
        {
          url: websiteUrl,
          companyId: companyId,
        },
        {
          headers: { Authorization: `Bearer ${currentToken}` },
        }
      )
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setCrawlWebsiteOpen(false);
        setWebsiteUrl("");
        setModalLoading(false);
        refreshData(path);
      });
  };

  const moveFile = async (folder: any) => {
    setRefreshing(true);
    const currentToken = await getToken();
    axios
      .post(
        `${hostname}/documents/move`,
        {
          documentId: moveFileId,
          companyId: companyId,
          folder: folder,
        },
        {
          headers: { Authorization: `Bearer ${currentToken}` },
        }
      )
      .then((res) => {
        setFolderSelectorOpen(false);
        refreshData(path);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  const createFolder = async () => {
    setModalLoading(true);
    const currentToken = await getToken();
    axios
      .post(
        `${hostname}/documents/folders`,
        {
          name: folderName,
          companyId: companyId,
          path: path,
        },
        {
          headers: { Authorization: `Bearer ${currentToken}` },
        }
      )
      .then(() => {
        refreshData(path);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setModalLoading(false);
        setCreateFolderOpen(false);
      });
  };

  const getPathBreadcrumbs = () => {
    if (path == "/") {
      return <link></link>;
    }
    return path
      .split("/")
      .filter((x) => x)
      .map((p, i) => (
        <Link
          component="button"
          key={p}
          underline="hover"
          color="inherit"
          onClick={() => {
            const currentPath = path
              .split("/")
              .slice(0, path.split("/").indexOf(p) + 1)
              .join("/");
            setPath(currentPath);
            refreshData(currentPath);
          }}
        >
          {(i == 0 && p === "shared" && (
            <Typography variant="caption" color="primary">
              {company.name} Shared
            </Typography>
          )) || <span>{p}</span>}
        </Link>
      ));
  };

  const deleteFolder = async (folder: Document) => {
    const currentToken = await getToken();
    const res = await axios.delete(
      `${hostname}/documents/folders/` + folder.id,
      {
        headers: { Authorization: `Bearer ${currentToken}` },
      }
    );
    const files = res.data.length;
    openDialog(
      "Delete Folder",
      `Are you sure you want to delete ${files} files/folders?`,
      "Confirm",
      () => () => {
        setRefreshing(true);
        axios
          .delete(`${hostname}/documents/folders/` + folder.id, {
            headers: { Authorization: `Bearer ${currentToken}` },
            params: { delete: true },
          })
          .then(() => {
            refreshData(path);
          })
          .catch((err) => {
            eventListener({
              type: "SET_ERROR",
              error: parseError(err),
            });
          });
      }
    );
    return res;
  };

  if (loading) {
    return <LoadingScreen open={loading} />;
  }

  return (
    <Box>
      <SelectEmployeeDialog
        open={selectEmployeesOpen}
        employees={employees}
        title="Select Employee"
        content="Choose an employee to discuss the document."
        buttonText="Submit"
        onClose={() => setSelectEmployeesOpen(false)}
        onSubmit={(employee: any) => {
          setSelectEmployeesOpen(false);
          chatWithDocument(employee);
        }}
      />
      {/* <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            color="inherit"
            component={RouterLink}
            to="/dashboard/companies"
          >
            Companies
          </Link>
          <Link
            underline="hover"
            color="inherit"
            component={RouterLink}
            to={`/dashboard/companies/${companyId}`}
          >
            {company.name}
          </Link>
          <Typography color="inherit">Documents</Typography>
        </Breadcrumbs>
        <Typography variant="h4" style={{ marginTop: 10 }}>
          Documents
        </Typography>
        <Typography style={{ marginTop: 10 }}>
          Uploading company documents can be a great way to train your AI
          Employees in context about your company. The information inside the
          documents can be used to provide additional context to the AI model
          for company relevant answers. Document tags can be used to organize
          your information and provide certain AI Employees with different
          scopes of interest.
        </Typography> */}
      <Button
        style={{ marginTop: 10 }}
        onClick={() => {
          setEditMode(false);
          setUploadDocumentMode(true);
          setFullWidthDialog(false);
          setModalOpen(true);
        }}
      >
        <UploadFileIcon style={{ marginRight: 3 }} /> Upload Document
      </Button>
      <Button
        style={{ marginTop: 10 }}
        onClick={() => {
          setEditMode(false);
          setUploadDocumentMode(false);
          setFullWidthDialog(true);
          setModalOpen(true);
          setNewDocument(getNewDocument());
        }}
      >
        <PostAddIcon style={{ marginRight: 3 }} /> Create Document
      </Button>
      <Button
        style={{ marginTop: 10 }}
        onClick={() => {
          setCreateFolderOpen(true);
          setFolderName("");
        }}
      >
        <CreateNewFolderIcon style={{ marginRight: 4 }} /> Create Folder
      </Button>
      <Button
        style={{ marginTop: 10 }}
        onClick={() => {
          setCrawlWebsiteOpen(true);
        }}
      >
        <TravelExploreIcon style={{ marginRight: 3 }} /> Import Website
      </Button>
      <Button
        onClick={() => {
          setTab("integrations");
        }}
        style={{ marginTop: 10 }}
      >
        <SyncIcon style={{ marginRight: 3 }} />
        Sync Settings
      </Button>
      <Paper style={{ marginTop: 20, marginBottom: 40 }} variant="outlined">
        {refreshing && <LinearProgress />}
        {!refreshing && <div style={{ height: 4 }}></div>}
        <div style={{ display: "flex" }}>
          <Breadcrumbs
            aria-label="breadcrumb"
            style={{ marginLeft: 10, marginTop: 8, flexGrow: 1 }}
          >
            <Link
              underline="hover"
              color="inherit"
              component="button"
              onClick={() => {
                setPath("/");
                refreshData("/");
              }}
            >
              Root
            </Link>
            {getPathBreadcrumbs()}
          </Breadcrumbs>
          <IconButton
            onClick={() => refreshData(path)}
            style={{ float: "right", marginRight: 3 }}
          >
            <RefreshIcon />
          </IconButton>
        </div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell component="th" scope="row" sortDirection="desc">
                  <TableSortLabel active={true} direction="desc">
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Size</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Updated</TableCell>
                <TableCell>Tags</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documents.map((row) => (
                <TableRow
                  key={row.id}
                  onClick={() => {
                    if (row.type !== "folder" && row.type !== "shared") {
                      setNewDocument({ ...row, content: "Loading..." });
                      setUploadDocumentMode(false);
                      setFullWidthDialog(true);
                      setEditMode(true);
                      setModalOpen(true);
                      getDocumentContents(row);
                    } else {
                      if (row.id === "shared") {
                        setPath("/shared");
                        refreshData("/shared");
                      } else if (path == "/") {
                        setPath(path + row.name);
                        refreshData(path + row.name);
                      } else {
                        setPath(path + "/" + row.name);
                        refreshData(path + "/" + row.name);
                      }
                    }
                  }}
                >
                  <TableCell>
                    {row.type === "folder" && row.id !== "shared" && (
                      <SourceIcon style={{ marginRight: 10 }} />
                    )}
                    {row.id === "shared" && (
                      <SourceIcon color="primary" style={{ marginRight: 10 }} />
                    )}
                    {row.type !== "folder" && row.id !== "shared" && (
                      <Description style={{ marginRight: 10 }} />
                    )}
                  </TableCell>
                  <TableCell>
                    {row.id === "shared" ? `${company.name} Shared` : row.name}
                  </TableCell>
                  <TableCell>{row.type}</TableCell>
                  <TableCell>{row.tokens}</TableCell>
                  <TableCell>{row.created}</TableCell>
                  <TableCell>{row.updated}</TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={1} justifyContent="end">
                      {row.tags.map((tag) => (
                        <Chip key={tag} label={tag} size="small" />
                      ))}
                    </Stack>
                  </TableCell>
                  <TableCell align="right">
                    {row.type === "folder" && (
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteFolder(row);
                          setOpenButton("");
                        }}
                      >
                        <DeleteOutline />
                      </IconButton>
                    )}
                    {row.type !== "folder" && row.type !== "shared" && (
                      <span>
                        <IconButton
                          onClick={(e: any) => {
                            e.stopPropagation();
                            setOpenButton(row.id);
                            setAnchorEl(e.currentTarget);
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={row.id === openButton}
                          onClose={(e: any) => {
                            setOpenButton("");
                            e.stopPropagation();
                          }}
                        >
                          <MenuItem
                            onClick={(e) => {
                              e.stopPropagation();
                              setNewDocument({
                                ...row,
                                content: "Loading...",
                              });
                              setUploadDocumentMode(false);
                              setFullWidthDialog(true);
                              setEditMode(true);
                              setModalOpen(true);
                              getDocumentContents(row);
                              setOpenButton("");
                            }}
                          >
                            Edit
                          </MenuItem>
                          <MenuItem
                            onClick={(e) => {
                              e.stopPropagation();
                              setMoveFileId(row.id);
                              setFolderSelectorOpen(true);
                              setOpenButton("");
                            }}
                          >
                            Move
                          </MenuItem>
                          <MenuItem
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectEmployeesOpen(true);
                              setOpenButton("");
                            }}
                          >
                            Chat with Document
                          </MenuItem>
                          <MenuItem
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteDocument(row);
                              setOpenButton("");
                            }}
                          >
                            Delete
                          </MenuItem>
                        </Menu>
                      </span>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Dialog
        open={crawlWebsiteOpen}
        onClose={() => setCrawlWebsiteOpen(false)}
        scroll="paper"
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Import Website Crawler
          </Typography>
          <TextField
            style={{ marginTop: 20 }}
            fullWidth
            value={websiteUrl}
            label="Website URL"
            onChange={(e) => setWebsiteUrl(e.target.value)}
          />
          <Button
            variant="outlined"
            style={{ marginTop: 20 }}
            onClick={() => crawlWebsite()}
          >
            Import
            {modalLoading && (
              <CircularProgress
                style={{ marginLeft: 10, float: "right" }}
                color="inherit"
                size={20}
              />
            )}
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        open={createFolderOpen}
        onClose={() => setCreateFolderOpen(false)}
        scroll="paper"
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Create Folder
          </Typography>
          <TextField
            style={{ marginTop: 20 }}
            fullWidth
            value={folderName}
            label="Name"
            onChange={(e) => setFolderName(e.target.value)}
          />
          <Button
            variant="outlined"
            style={{ marginTop: 20 }}
            onClick={() => createFolder()}
          >
            Create
            {modalLoading && (
              <CircularProgress
                style={{ marginLeft: 10, float: "right" }}
                color="inherit"
                size={20}
              />
            )}
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        scroll="paper"
        maxWidth="lg"
        fullWidth={fullWidthDialog}
      >
        <DialogContent>
          {!editMode && !uploadDocumentMode && (
            <div>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Create Document
              </Typography>
              <TextField
                style={{ marginTop: 20 }}
                fullWidth
                value={newDocument.name}
                label="Name"
                onChange={(e) =>
                  setNewDocument({ ...newDocument, name: e.target.value })
                }
              />
              <Autocomplete
                multiple
                freeSolo
                filterSelectedOptions
                style={{ marginTop: 20 }}
                options={tags}
                getOptionLabel={(option) => option}
                onChange={(e, v) => setNewDocument({ ...newDocument, tags: v })}
                renderInput={(params) => (
                  <TextField {...params} label="Tags" placeholder="Add Tags" />
                )}
              />
              <TextField
                style={{ marginTop: 20 }}
                fullWidth
                multiline
                minRows={8}
                value={newDocument.content}
                label="Content"
                onChange={(e) =>
                  setNewDocument({ ...newDocument, content: e.target.value })
                }
              />
              <Button
                variant="outlined"
                style={{ marginTop: 20 }}
                onClick={() =>
                  uploadDocumentFile(
                    undefined,
                    newDocument.content,
                    newDocument.name
                  )
                }
              >
                Create
                {modalLoading && (
                  <CircularProgress
                    style={{ marginLeft: 10, float: "right" }}
                    color="inherit"
                    size={20}
                  />
                )}
              </Button>
            </div>
          )}
          {editMode && (
            <div>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Edit Document
              </Typography>
              <TextField
                style={{ marginTop: 20 }}
                fullWidth
                value={newDocument.name}
                label="Name"
                onChange={(e) =>
                  setNewDocument({ ...newDocument, name: e.target.value })
                }
              />
              <Autocomplete
                multiple
                freeSolo
                filterSelectedOptions
                style={{ marginTop: 20 }}
                options={tags}
                value={newDocument.tags}
                getOptionLabel={(option) => option}
                onChange={(e, v) => setNewDocument({ ...newDocument, tags: v })}
                renderInput={(params) => (
                  <TextField {...params} label="Tags" placeholder="Add Tags" />
                )}
              />
              <TextField
                fullWidth
                style={{ marginTop: 20 }}
                multiline
                rows={10}
                value={newDocument.content}
                onChange={(e) => {
                  setNewDocument({ ...newDocument, content: e.target.value });
                }}
              />
              <Button
                variant="outlined"
                style={{ marginTop: 20, marginRight: 10 }}
                onClick={() => editDocument(newDocument)}
              >
                Save
                {modalLoading && (
                  <CircularProgress
                    style={{ marginLeft: 10, float: "right" }}
                    color="inherit"
                    size={20}
                  />
                )}
              </Button>
              <Button
                variant="outlined"
                style={{ marginTop: 20 }}
                onClick={() => {
                  setSelectEmployeesOpen(true);
                }}
              >
                Chat with Document
              </Button>
            </div>
          )}
          {uploadDocumentMode && (
            <div>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Upload Document or Audio
              </Typography>
              <Typography variant="body1">
                Supported Formats: .doc, .docx, .pdf, .csv, .xlsx, .xls, .m4a,
                .mp3, .mp4, .webm
              </Typography>
              <Typography
                variant="body2"
                style={{ marginTop: 10, width: "100%", marginBottom: 5 }}
              >
                Audio formats will be transcribed to a text document
              </Typography>
              <Button
                variant="outlined"
                style={{ marginTop: 10 }}
                component="label"
              >
                Select File
                <input
                  hidden
                  accept=".doc,.docx,.pdf,.csv,.xlsx,.xls,.m4a,.mp3,.mp4,.webm"
                  type="file"
                  onChange={(event) => {
                    if (
                      event.target &&
                      event.target.files &&
                      event.target.files.length > 0
                    ) {
                      setSelectedFile(event.target.files[0]);
                    }
                  }}
                />
              </Button>
              {selectedFile !== undefined && (
                <Typography
                  style={{ marginTop: 10, marginRight: 10, marginBottom: 10 }}
                  variant="body2"
                >
                  File: {selectedFile.name}
                </Typography>
              )}
              <Autocomplete
                multiple
                freeSolo
                filterSelectedOptions
                style={{ marginTop: 10 }}
                options={tags}
                getOptionLabel={(option) => option}
                onChange={(e, v) => setNewDocument({ ...newDocument, tags: v })}
                renderInput={(params) => (
                  <TextField {...params} label="Tags" placeholder="Add Tags" />
                )}
              />
              <Button
                variant="outlined"
                style={{ marginTop: 10 }}
                onClick={() => {
                  if (!selectedFile) return;
                  uploadDocumentFile(selectedFile, undefined, undefined);
                }}
              >
                Upload
                {modalLoading && (
                  <CircularProgress
                    style={{ marginLeft: 10, float: "right" }}
                    color="inherit"
                    size={20}
                  />
                )}
              </Button>
            </div>
          )}
        </DialogContent>
      </Dialog>
      <FolderSelector
        open={folderSelectorOpen}
        getToken={getToken}
        company={company}
        onClick={(folder: any) => moveFile(folder)}
        onClose={() => setFolderSelectorOpen(false)}
        eventListener={eventListener}
      />
    </Box>
  );
};
