import PlayCircleFilledOutlined from "@mui/icons-material/PlayCircleFilledOutlined";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { hostname, parseError } from "./utils";
export default ({
  open,
  onClose,
  workflow,
  getToken,
  companyId,
  eventListener,
}: {
  open: boolean;
  onClose: any;
  workflow: any;
  getToken: Function;
  companyId: string;
  eventListener: Function;
}) => {
  const [values, setValues] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [inputFields, setInputFields] = useState([]);
  const [output, setOutput] = useState("");

  const convertFiles = async (files: Array<File>) => {
    const formData = new FormData();
    for (var i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    const currentToken = await getToken();
    return await axios
      .post(`${hostname}/documents/convert`, formData, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res: any) => {
        return res.data.documents;
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: err,
        });
      });
  };

  const handleChange = async (event: any) => {
    console.log(event);
    const { name, value, files } = event.target;
    console.log(name, value, files);
    if (!value) return;
    if (files) {
      const docs = await convertFiles(files);
      setValues((prevValues: any) => ({
        ...prevValues,
        [name]: { value: value, type: "file", data: docs },
      }));
    } else {
      setValues((prevValues: any) => ({
        ...prevValues,
        [name]: { value, type: "text" },
      }));
    }
  };

  const handleSubmit = async () => {
    const currentToken = await getToken();
    setLoading(true);
    await axios
      .post(
        `${hostname}/workflows/runs`,
        {
          companyId: companyId,
          workflowId: workflow.id,
          inputValues: values,
        },
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        setOutput(response.data.output);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!workflow || !workflow.nodes) return;
    const inputNodes = workflow.nodes.filter(
      (node: any) => node.data.type === "input"
    );
    const combinedInputFields = inputNodes.reduce(
      (acc: Array<any>, node: any) => {
        console.log("nodes", node);
        (node.data.inputFields || []).forEach((field: any) => {
          if (
            !acc.some(
              (f: any) => f.label === field.label && f.type === field.type
            )
          ) {
            acc.push(field);
          }
        });

        return acc;
      },
      []
    );
    setInputFields(combinedInputFields);
  }, [workflow]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Trigger Workflow</DialogTitle>
      <DialogContent>
        {inputFields.map((field: any, index: number) => (
          <TextField
            key={index}
            label={field.label}
            name={field.label.replace(/\s/g, "_").toLowerCase()}
            type={field.type === "textarea" ? "text" : field.type}
            multiline={field.type === "textarea"}
            rows={field.type === "textarea" ? 3 : 1}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
        ))}
        {output && <ReactMarkdown children={output} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="outlined"
          startIcon={
            loading ? (
              <CircularProgress size={20} />
            ) : (
              <PlayCircleFilledOutlined />
            )
          }
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
