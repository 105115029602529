import React from "react";
import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";

const BlogCard = ({
  title,
  summary,
  imageSrc,
  postId,
  name,
}: {
  title: string;
  summary: string;
  imageSrc: string;
  postId: string;
  name: string;
}) => {
  return (
    <Card>
      <CardActionArea href={`/blog/${name}`}>
        {imageSrc && (
          <CardMedia
            height="200"
            component="img"
            image={imageSrc}
            alt={title}
          />
        )}
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            style={{
              overflow: "hidden",
              height: 66,
              display: "-webkit-box",
              WebkitLineClamp: 2,
              lineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            style={{
              overflow: "hidden",
              height: 80,
              marginTop: 10,
              marginBottom: 5,
              display: "-webkit-box",
              WebkitLineClamp: 4,
              lineClamp: 4,
              WebkitBoxOrient: "vertical",
            }}
          >
            {summary.slice(0, 165) + (summary.length > 165 ? "..." : "")}
          </Typography>
          <Typography
            variant="body2"
            style={{
              marginTop: 15,
              flexDirection: "row",
              alignItems: "center",
              display: "flex",
            }}
            gutterBottom
          >
            <Avatar
              src={require("./img/author.jpeg")}
              alt="David Richards"
              sx={{ width: 24, height: 24, marginRight: 1 }}
            />
            David Richards
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default BlogCard;
