import MoreVert from "@mui/icons-material/MoreVert";
import {
  Card,
  Fade,
  IconButton,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { memo, useState } from "react";
import { Handle, Position } from "reactflow";

export default memo(
  ({
    id,
    data,
    isConnectable,
  }: {
    id: string;
    data: any;
    isConnectable: boolean;
  }) => {
    const [editing, setEditing] = useState(false);
    const [name, setName] = useState(data.name || "");
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const onDelete = (e: any) => {
      e.stopPropagation();
      data.onDelete(id);
    };

    return (
      <Card
        variant="outlined"
        sx={{
          p: 1,
          display: "flex",
        }}
      >
        <ListItem>
          <ListItemText
            sx={{ display: "block", maxWidth: 200 }}
            primary={data.label}
            secondary={data.name || data.description}
            primaryTypographyProps={{
              style: {
                display: "block",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            }}
          />

          <IconButton sx={{ ml: 2 }} onClick={handleClick}>
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={anchorEl !== null}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={onDelete}>Delete</MenuItem>
          </Menu>
        </ListItem>
        <Handle
          position={Position.Left}
          isConnectable={isConnectable}
          id="a"
          type="target"
        />
        <Handle
          position={Position.Right}
          isConnectable={isConnectable}
          id="d"
          type="source"
        />
      </Card>
    );
  }
);
