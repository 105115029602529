import { Dialog, DialogContent, Typography } from "@mui/material";
import React from "react";
import { CopyBlock, dracula } from "react-code-blocks";
import ReactMarkdown from "react-markdown";

export default ({
  open,
  onClose,
  workflowRun,
}: {
  open: boolean;
  onClose: any;
  workflowRun: any;
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogContent>
        {workflowRun.error && (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>Error:</Typography>
            <CopyBlock
              customStyle={{ overflow: "scroll" }}
              text={workflowRun.error}
              showLineNumbers
              theme={dracula}
              wrapLines
              codeBlock
            />
          </React.Fragment>
        )}
        <Typography sx={{ mt: 2, mb: 1 }}>Log:</Typography>
        <CopyBlock
          customStyle={{ overflow: "scroll" }}
          text={(workflowRun.log || [])
            .map((x: any) => `${x.ts} ${x.msg}`)
            .join("\n")}
          showLineNumbers
          theme={dracula}
          wrapLines
          codeBlock
        />
        <Typography sx={{ mt: 4, mb: 1 }}>Output:</Typography>
        <ReactMarkdown children={workflowRun.output} />
      </DialogContent>
    </Dialog>
  );
};
