import { Box, Button, Fade, Link, Typography } from "@mui/material";
import { ParallaxBanner } from "react-scroll-parallax";

const HeroSection = ({
  title,
  subtitle,
  imgSrc,
}: {
  title: string;
  subtitle: string;
  imgSrc: string;
}) => {
  return (
    <section
      style={{
        width: "100%",
        height: "100vh",
        position: "relative",
        display: "flex",
        zIndex: 0,
      }}
    >
      <ParallaxBanner layers={[{ image: imgSrc, speed: -50 }]} />
      <div
        style={{
          opacity: 0.9,
          backgroundColor: "#222831",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      >
        <Fade in={true} timeout={4700}>
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            color="#fff"
          >
            <Box
              component="img"
              style={{ maxWidth: "300px" }}
              alt="Parallel AI Logo"
              src={require("./img/logo_transparent_dark.png")}
            />
            <Typography
              variant="h6"
              component="h6"
              style={{
                paddingBottom: 10,
                margin: 10,
                background:
                  "linear-gradient(91.54deg,#4affe0 -8.45%,#ffc94a 103.78%)",
                WebkitBackgroundClip: "text",
                backgroundClip: "text",
                color: "transparent",
              }}
            >
              {subtitle}
            </Typography>
            <div>
              <Button component={Link} href="/signup">
                Sign Up
              </Button>
              <Button component={Link} href="/login">
                Login
              </Button>
            </div>
          </Box>
        </Fade>
      </div>
    </section>
  );
};

export default HeroSection;
