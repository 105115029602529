import { User } from "firebase/auth";

export default function (name: string, data: any) {
  // @ts-ignore
  if (window.posthog) {
    // @ts-ignore
    window.posthog.capture(name, data);
  }
}

export function identify(user: User) {
  // @ts-ignore
  if (window.posthog) {
    var firstName = "";
    var lastName = "";
    var nameParts = user.displayName?.split(" ");
    if (nameParts) {
      if (nameParts.length > 0) {
        firstName = nameParts[0];
      }
      if (nameParts.length > 1) {
        lastName = nameParts[1];
      }
    }
    var distictId = "";
    try {
      // @ts-ignore
      distictId = window.posthog.get_distinct_id();
    } catch {
      distictId = "";
    }
    if (distictId === user.email) {
      return;
    }

    // @ts-ignore
    window.posthog.identify(user.email, {
      email: user.email,
      displayName: user.displayName,
      firstName: firstName,
      lastName: lastName,
      uid: user.uid,
    });
  }
}
