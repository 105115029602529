import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Box,
  Container,
  Typography,
} from "@mui/material";
import BlogCard from "./BlogCard"; // Ensure you have the BlogCard component
import Header from "./Header";
import { posts } from "./constants";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

const BlogList = ({
  setThemeForOnePage,
}: {
  setThemeForOnePage: (theme: string) => void;
}) => {
  setThemeForOnePage("dark");
  return (
    <Box
      style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
    >
      <Helmet>
        <title>Blog - Parallel AI</title>
        <meta
          name="description"
          content="Explore Parallel AI's blog for expert articles, product updates, and
          tips to optimize your experience. Stay informed and make the most of
          Parallel AI."
        />
        <meta property="og:title" content="Blog - Parallel AI" />
        <meta
          property="og:description"
          content="Explore Parallel AI's blog for expert articles, product updates, and
          tips to optimize your experience. Stay informed and make the most of
          Parallel AI."
        />
      </Helmet>
      <Header mode={"dark"} maxWidth={"lg"} />
      <Container style={{ paddingTop: 50, paddingBottom: 50, flexGrow: 1 }}>
        <Typography
          variant="h2"
          component="h1"
          style={{ marginBottom: 30 }}
          maxWidth={"lg"}
        >
          Blog
        </Typography>
        <Typography variant="h6" component="h2" style={{ marginBottom: 30 }}>
          Explore Parallel AI's blog for expert articles, product updates, and
          tips to optimize your experience. Stay informed and make the most of
          Parallel AI.
        </Typography>
        <Grid container spacing={4}>
          {posts.map((post: any) => (
            <Grid item key={post.name} xs={12} sm={6} md={4}>
              <BlogCard
                imageSrc={post.imageSrc}
                title={post.title}
                summary={post.summary}
                postId={post.id}
                name={post.name}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
      <Footer />
    </Box>
  );
};

export default BlogList;
