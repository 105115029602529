import { Container, Grid, Link, Typography } from "@mui/material";

export default function Footer() {
  return (
    <Container>
      <Grid container style={{ marginTop: 55, marginBottom: 25 }}>
        <Grid item xs={12} sm={3} style={{ textAlign: "center" }}>
          <Link href="/privacy" underline="hover" style={{ marginRight: 10 }}>
            Privacy
          </Link>
          <span style={{ marginRight: 10 }}>/</span>
          <Link href="/terms" underline="hover">
            Terms
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} style={{ textAlign: "center" }}>
          <Typography variant="caption">
            Copyright Parallel AI 2024. All rights reserved.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3} style={{ textAlign: "center" }}>
          <Link
            href="#"
            underline="hover"
            style={{ marginRight: 10 }}
            onClick={() =>
              window.open("https://discord.gg/55t6GQSJbM", "_blank")
            }
          >
            Support
          </Link>
          <span style={{ marginRight: 10 }}>/</span>
          <Link href="/blog" underline="hover" style={{ marginRight: 10 }}>
            Blog
          </Link>
          <span style={{ marginRight: 10 }}>/</span>
          <Link
            href="https://parallelai.trackdesk.com/sign-up"
            underline="hover"
          >
            Affiliate Program
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
}
