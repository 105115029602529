import { Firestore } from "@firebase/firestore";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import Verified from "@mui/icons-material/Verified";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Slider,
  Typography,
} from "@mui/material";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { User } from "firebase/auth";
import moment from "moment";
import { SyntheticEvent, useState } from "react";
import LoadingScreen from "./LoadingScreen";
import PricingBlocks from "./PricingBlocks";
import capture from "./capture";
import { hostname, parseError } from "./utils";

const PRICE_PER_SEAT = 25;
const stripePromise = loadStripe(
  "pk_live_51MuFZwGoLqtAHD3fnUS8zbVzaykqoPe8O2sIDdKUHqRU3Opye0wuLh3YBp6qsJUfdUmsAbeAe26weZLwOf09g93n00oU7f3XYN"
);

const CheckoutForm = ({
  displayName,
  email,
  setModalOpen,
  getUsage,
  token,
  getToken,
  eventListener,
  productPlan,
  billingPeriod,
  mode,
}: {
  displayName: string;
  email: string;
  setModalOpen: Function;
  getUsage: Function;
  token: string;
  getToken: Function;
  eventListener: Function;
  productPlan: string;
  billingPeriod: string;
  mode: string;
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [modalLoading, setModalLoading] = useState(false);
  const [seats, setSeats] = useState(1);
  const [price, setPrice] = useState(12);

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    if (elements === null || stripe === null) {
      return;
    }

    try {
      // @ts-ignore
      const { paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: {
          name: displayName,
          email,
        },
      });

      if (!paymentMethod) return;

      setModalLoading(true);
      const currentToken = await getToken();
      const response = await axios.post(
        `${hostname}/subscriptions/create`,
        {
          paymentMethod: paymentMethod?.id,
          name: displayName,
          email: email,
          seats: productPlan === "individual" ? 1 : seats,
          billingPeriod: billingPeriod,
          price: price,
          productPlan: productPlan,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${currentToken}`,
          },
        }
      );
      const confirmPayment = await stripe?.confirmCardPayment(
        response.data.clientSecret
      );
      capture("Subscription Success", {
        productPlan: productPlan,
        billingPeriod: billingPeriod,
      });
      setModalOpen(false);
      setModalLoading(false);
      getUsage();
      // @ts-ignore
      if (window && window.fpr) {
        // @ts-ignore
        window.fpr("referral", { email: email, uid: response.data.customerId });
      }
    } catch (err) {
      setModalLoading(false);
      capture("Subscription Error", { productPlan: productPlan });
      eventListener({
        type: "SET_ERROR",
        error: parseError(err),
      });
    }
  };

  const getIcon = (checked: boolean) => {
    if (checked) {
      return <CheckCircleOutlineIcon />;
    }
    return <RadioButtonUncheckedIcon />;
  };

  const getTotalPrice = () => {
    var currentSeats = seats;
    if (seats < 3 && productPlan === "business") {
      setSeats(3);
      currentSeats = 3;
    } else if (productPlan === "individual") {
      currentSeats = 1;
    }
    if (billingPeriod === "annual") {
      if (productPlan === "business") {
        return "$" + 144 * currentSeats;
      } else {
        return "$168";
      }
    } else {
      if (productPlan === "business") {
        return "$" + currentSeats * 25;
      } else {
        return "$" + 29;
      }
    }
  };

  const getMonthlyPrice = () => {
    var currentSeats = seats;
    if (seats < 3 && productPlan === "business") {
      currentSeats = 3;
    } else if (productPlan === "individual") {
      currentSeats = 1;
    }
    if (billingPeriod === "annual") {
      return "$" + 12 * currentSeats;
    } else {
      return "$" + 25 * currentSeats;
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit} style={{ marginTop: 30, maxWidth: 450 }}>
        {productPlan === "business" && (
          <div>
            <Typography>Number of Seats</Typography>
            <Slider
              min={3}
              max={30}
              step={1}
              value={seats}
              getAriaValueText={(x, y) => x.toString()}
              onChange={(e: any) => {
                setSeats(e.target.value);
              }}
              valueLabelDisplay="auto"
            />
            <Typography>
              Seats:{" "}
              <Typography component={"span"} variant="h5">
                {seats}
              </Typography>
            </Typography>
            <Typography>
              Price Per Month:{" "}
              <Typography component={"span"} variant="h5">
                {getMonthlyPrice()}
              </Typography>
            </Typography>
          </div>
        )}

        {billingPeriod === "annual" &&
          ["business", "individual"].includes(productPlan) && (
            <Typography sx={{ mb: 4 }}>
              Due Today:{" "}
              <Typography component={"span"} variant="h5">
                {getTotalPrice()}
              </Typography>
            </Typography>
          )}

        <CardElement
          options={{
            style: {
              base: {
                iconColor: mode === "dark" ? "#FFFFFF" : "#29A19C",
                color: mode === "dark" ? "#FFFFFF" : "#000000",
                fontSize: "20px",
                "::placeholder": {
                  color: mode === "dark" ? "#FFFFFF" : "#29A19C",
                },
              },
            },
          }}
        />

        <Button
          type="submit"
          variant="outlined"
          disabled={!stripe || !elements || productPlan === "free"}
          style={{ marginTop: 35 }}
          endIcon={
            modalLoading && (
              <CircularProgress
                style={{ marginLeft: 10, float: "right" }}
                color="inherit"
                size={20}
              />
            )
          }
        >
          Subscribe
        </Button>
      </form>
    </Container>
  );
};

export default ({
  db,
  currentUser,
  token,
  getToken,
  eventListener,
  mode,
}: {
  db: Firestore;
  currentUser: any | User;
  token: any | string;
  getToken: Function;
  eventListener: Function;
  mode: string;
}) => {
  const [usage, setUsage] = useState<any>({});
  const [init, setInit] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [apiKey, setApiKey] = useState("");
  const [keySaveLoading, setKeySaveLoading] = useState(false);
  const [planSelection, setPlanSelection] = useState("free");
  const [billingPeriod, setBillingPeriod] = useState("annual");

  if (!currentUser.uid) {
    return <></>;
  }

  const getUsage = async () => {
    const currentToken = await getToken();
    await axios
      .get(`${hostname}/usage`, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        capture("Usage", res.data.usage);
        setUsage(res.data.usage);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  const getKeys = async () => {
    const currentToken = await getToken();
    await axios
      .get(`${hostname}/keys`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        setApiKey(res.data.openai);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  const getData = async () => {
    //getKeys();
    await getUsage();
    setInit(true);
    setLoading(false);
  };

  if (currentUser.uid && !init) {
    getData();
  }

  const getCurrrentUsage = (): number => {
    if (!usage) return 0;
    const dateKey = "credits-" + moment().format("YYYY-M");
    if (dateKey in usage) {
      return usage[dateKey].toFixed(1);
    }
    return 0;
  };

  const getCurrentDocUsage = (): number => {
    if (!usage) return 0;
    return usage.documentUsage || 0;
  };

  const getDocumentLimit = (): number => {
    if (!usage) return 10;
    return usage.documentLimit || 10;
  };

  const getDocumentProgress = (): number => {
    return (getCurrentDocUsage() / getDocumentLimit()) * 100;
  };

  const getLimit = (): number => {
    if (!usage) {
      return 50;
    }
    const limit = usage["creditLimit"] || 50;
    return limit;
  };

  const getProgress = (): number => {
    return (getCurrrentUsage() / getLimit()) * 100;
  };

  const getSubscriptionType = (): string => {
    if (!usage) {
      return "Free Tier";
    }
    return usage.subscriptionType || "Free Tier";
  };

  const saveApiKey = () => {
    setKeySaveLoading(true);
    axios
      .post(
        `${hostname}/keys`,
        {
          type: "openai",
          key: apiKey,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setKeySaveLoading(false);
      });
  };

  if (loading) {
    return <LoadingScreen open={loading} />;
  }

  return (
    <Box>
      <Container>
        <Avatar
          style={{ marginTop: 20 }}
          alt={currentUser.displayName}
          sx={{ width: 60, height: 60 }}
          src={currentUser.photoURL}
        />
        <Typography style={{ marginTop: 20 }} variant="h6">
          {currentUser.displayName}
        </Typography>
        <Typography variant="body1" style={{ marginTop: 20 }}>
          Subscription Type:{" "}
          {getSubscriptionType() !== "Free Tier" && (
            <Verified fontSize="small" style={{ verticalAlign: -4 }} />
          )}{" "}
          {getSubscriptionType()}
        </Typography>
        <Typography style={{ marginTop: 20 }} variant="body1">
          Credits used {getCurrrentUsage()} of {getLimit()} monthly limit
        </Typography>
        <LinearProgress
          style={{ marginTop: 20 }}
          variant="determinate"
          value={getProgress()}
        />
        <Typography style={{ marginTop: 20 }} variant="body1">
          Documents used {getCurrentDocUsage()} of {getDocumentLimit()} limit
        </Typography>
        <LinearProgress
          style={{ marginTop: 20 }}
          variant="determinate"
          value={getDocumentProgress()}
        />
        {(getSubscriptionType() === "Free Tier" ||
          window.location.hostname === "localhost") && (
          <Button
            variant="outlined"
            style={{ marginTop: 20 }}
            onClick={() => {
              capture("Subscribe Open", {});
              setModalOpen(true);
            }}
          >
            Subscription
          </Button>
        )}
      </Container>
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        maxWidth="lg"
      >
        <DialogTitle>Subscription</DialogTitle>
        <DialogContent>
          <PricingBlocks
            height={560}
            planSelector={true}
            selection={planSelection}
            billingPeriod={billingPeriod}
            onBillingPeriod={(v) => setBillingPeriod(v)}
            onPlanSelection={(plan) => setPlanSelection(plan)}
          />
          <Elements stripe={stripePromise}>
            <CheckoutForm
              setModalOpen={setModalOpen}
              getUsage={getUsage}
              displayName={currentUser.displayName}
              email={currentUser.email}
              token={token}
              getToken={getToken}
              eventListener={eventListener}
              productPlan={planSelection}
              billingPeriod={billingPeriod}
              mode={mode}
            />
          </Elements>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
