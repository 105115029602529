import { useEffect, useState } from "react";
import {
  Box,
  Container,
  Paper,
  Typography,
  Button,
  Avatar,
  Grid,
  Link,
} from "@mui/material";
import * as firebaseui from "firebaseui";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { Auth, User, browserLocalPersistence } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { identify } from "./capture";
const darkLogo = require("./img/logo_transparent_dark.png");
const logo = require("./img/logo_transparent.png");
import { trackAffiliate } from "./utils";

const Login = ({
  auth,
  mode,
  isLoginParam,
}: {
  auth: Auth;
  mode: string;
  isLoginParam: boolean;
}) => {
  const [isLogin, setIsLogin] = useState(isLoginParam);
  const navigate = useNavigate();
  var uiConfig: firebaseui.auth.Config = {
    signInFlow: "popup",
    //signInSuccessUrl: "/dashboard/companies",
    signInOptions: [
      // Leave the lines as is for the providers you want to offer your users.
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    // tosUrl and privacyPolicyUrl accept either url string or a callback
    // function.
    // Terms of service url/callback.
    tosUrl: "terms",
    // Privacy policy url/callback.
    privacyPolicyUrl: "privacy",
    callbacks: {
      signInSuccessWithAuthResult: function (authResult, redirectUrl: string) {
        return true;
      },
      signInFailure: function (error) {
        console.log(error);
      },
    },
  };
  // Add presistance to the auth object
  auth.setPersistence(browserLocalPersistence);
  // Initialize the FirebaseUI Widget using Firebase.
  var ui =
    firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);
  // The start method will wait until the DOM is loaded.
  ui.start("#firebaseui-auth-container", uiConfig);

  auth.onAuthStateChanged((user) => {
    if (user) {
      identify(user);
      navigate("/dashboard/companies");
      trackAffiliate(user);
    }
  });

  return (
    <Box>
      <Paper
        style={{
          width: 300,
          top: "50%",
          left: "50%",
          position: "fixed",
          marginTop: -150,
          marginLeft: -150,
        }}
      >
        <Grid container>
          <Grid item xs={12} style={{ textAlign: "center", margin: 20 }}>
            <img
              style={{ margin: "auto", height: 35 }}
              src={mode === "dark" ? darkLogo : logo}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            {isLogin ? "Login" : "Sign Up"}
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center", marginTop: 10 }}>
            <div id="firebaseui-auth-container"></div>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center", marginBottom: 10 }}>
            {!isLogin && (
              <Typography variant="body2">
                Already have an account?{" "}
                <Link component="button" onClick={() => setIsLogin(true)}>
                  Login
                </Link>
              </Typography>
            )}
            {isLogin && (
              <Typography variant="body2">
                Don't have an account yet?{" "}
                <Link component="button" onClick={() => setIsLogin(false)}>
                  Sign Up
                </Link>
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center", marginBottom: 20 }}>
            <Typography variant="body2">
              <Link href="/login/sso">SSO Login</Link>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default Login;
