import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from "@mui/material";
import React from "react";

const OutlineEditor = ({
  open,
  outline,
  setOutline,
  onClose,
  submitOutline,
  eventListener,
}: {
  open: boolean;
  outline: any;
  setOutline: Function;
  submitOutline: (e: any) => void;
  onClose: () => void;
  eventListener: Function;
}) => {
  if (!outline.title) {
    return <div></div>;
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle>Outline Editor</DialogTitle>
      <form onSubmit={submitOutline}>
        <DialogContent>
          <TextField
            label="Title"
            fullWidth
            value={outline.title}
            required
            onChange={(e) => setOutline({ ...outline, title: e.target.value })}
            style={{ marginBottom: 20 }}
          />
          <TextField
            label="Image Prompt"
            fullWidth
            value={outline.imagePrompt}
            required
            onChange={(e) =>
              setOutline({ ...outline, imagePrompt: e.target.value })
            }
            style={{ marginBottom: 20 }}
          />
          <TextField
            label="URL Slug"
            fullWidth
            value={outline.urlSlug}
            required
            onChange={(e) =>
              setOutline({ ...outline, urlSlug: e.target.value })
            }
            style={{ marginBottom: 20 }}
          />
          <Autocomplete
            multiple
            freeSolo
            options={[]}
            style={{ marginBottom: 20 }}
            value={outline.seoKeywords}
            getOptionLabel={(option: string) => option}
            onChange={(e, v) => setOutline({ ...outline, seoKeywords: v })}
            renderInput={(params) => (
              <TextField
                {...params}
                label="SEO Keywords"
                placeholder="Add Keywords"
              />
            )}
          />
          {(outline.sections || []).map((section: any, index: number) => (
            <React.Fragment key={index}>
              <Divider style={{ marginBottom: 15 }} />
              <TextField
                label="Heading"
                value={section.heading}
                fullWidth
                onChange={(e) => {
                  outline.sections[index].heading = e.target.value;
                  setOutline({ ...outline });
                }}
                style={{ marginBottom: 15 }}
              />
              <Autocomplete
                multiple
                freeSolo
                options={[]}
                style={{ marginBottom: 15 }}
                value={section.searchQueries}
                getOptionLabel={(option: string) => option}
                onChange={(e, v) => {
                  outline.sections[index].searchQueries = v;
                  setOutline({ ...outline });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Research Queries"
                    placeholder="Add Queries"
                  />
                )}
              />
              {(section.sections || []).map(
                (subSection: any, subIndex: number) => (
                  <div key={subIndex} style={{ marginLeft: 30 }}>
                    <Divider style={{ marginBottom: 15 }} />
                    <TextField
                      label="Sub Heading"
                      value={subSection.heading}
                      fullWidth
                      onChange={(e) => {
                        outline.sections[index].sections[subIndex].heading =
                          e.target.value;
                        setOutline({ ...outline });
                      }}
                      style={{ marginBottom: 15 }}
                    />
                    <Autocomplete
                      multiple
                      freeSolo
                      options={[]}
                      style={{ marginBottom: 15 }}
                      value={subSection.searchQueries}
                      getOptionLabel={(option: string) => option}
                      onChange={(e, v) => {
                        outline.sections[index].sections[
                          subIndex
                        ].searchQueries = v;
                        setOutline({ ...outline });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Research Queries"
                          placeholder="Add Queries"
                        />
                      )}
                    />
                  </div>
                )
              )}
            </React.Fragment>
          ))}
        </DialogContent>
        <DialogActions>
          <Button type="submit">Generate Article</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default OutlineEditor;
