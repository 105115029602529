import Check from "@mui/icons-material/Check";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Link,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";

export default ({
  height = 500,
  planSelector = false,
  selection = "",
  billingPeriod = "monthly",
  onPlanSelection = () => {},
  onBillingPeriod = () => {},
}: {
  height: number;
  planSelector: boolean;
  selection: string;
  billingPeriod: string;
  onPlanSelection: (plan: string) => void;
  onBillingPeriod: (period: string) => void;
}) => {
  const theme = useTheme();
  const getButton = (buttonForPlan: string) => {
    if (planSelector) {
      if (buttonForPlan === selection) {
        return (
          <Button
            color="primary"
            variant="outlined"
            onClick={() => onPlanSelection(buttonForPlan)}
            style={{ marginTop: 20 }}
            startIcon={<Check />}
          >
            Selected
          </Button>
        );
      } else {
        return (
          <Button
            color="inherit"
            variant="outlined"
            onClick={() => onPlanSelection(buttonForPlan)}
            style={{ marginTop: 20 }}
          >
            Select Plan
          </Button>
        );
      }
    } else {
      return (
        <Button
          variant="outlined"
          component={Link}
          href="/signup"
          style={{ marginTop: 20 }}
          color="inherit"
        >
          Get Started
        </Button>
      );
    }
  };

  return (
    <Grid container style={{ marginTop: 20 }}>
      <Grid item md={12}>
        <ToggleButtonGroup
          sx={{ mb: 2 }}
          color="primary"
          value={billingPeriod}
          exclusive
          onChange={(e: any) => onBillingPeriod(e.target.value)}
        >
          <ToggleButton value="monthly">Monthly Billing</ToggleButton>
          <ToggleButton value="annual">Annual Billing (Save +50%)</ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item md={6}>
        <Divider textAlign="left">For Individuals</Divider>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Card
              variant="outlined"
              style={{
                margin: 10,
                height: height,
                padding: 10,
                backgroundColor: "transparent",
              }}
            >
              <Typography variant="h5">Free</Typography>
              <Typography style={{ marginTop: 10 }}>
                For individuals just getting started with Parallel AI
              </Typography>
              <Typography variant="h3" style={{ marginTop: 10 }}>
                $0
              </Typography>
              {getButton("free")}
              <div style={{ height: 45 }}></div>
              <Divider style={{ marginTop: 10 }} />
              <Typography>
                <Check style={{ marginTop: 5, marginRight: 10 }} />
                50 questions / month
              </Typography>
              <Typography>
                <Check style={{ marginTop: 5, marginRight: 10 }} />
                10 training documents
              </Typography>
              <Typography>
                <Check style={{ marginTop: 5, marginRight: 10 }} />
                Access to the top AI models
              </Typography>
              <Typography>
                <Check style={{ marginTop: 5, marginRight: 10 }} />
                Chat with your documents
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card
              variant="outlined"
              style={{
                margin: 10,
                height: height,
                padding: 10,
                backgroundColor: "transparent",
              }}
            >
              <Typography variant="h5">Individual</Typography>
              <Typography style={{ marginTop: 10 }}>
                For individuals looking to amplify their productivity
              </Typography>

              <Box sx={{ flexDirection: "row", display: "flex" }}>
                <Typography variant="h3" style={{ marginTop: 10 }}>
                  {billingPeriod === "annual" ? "$14" : "$29"}
                </Typography>
                <Typography sx={{ mt: 1.5, ml: 1 }}>per month</Typography>
              </Box>
              {billingPeriod === "annual" && (
                <Typography variant="body1" sx={{ mt: 1 }}>
                  <strong>$168</strong> per year
                </Typography>
              )}
              {getButton("individual")}
              <div style={{ marginTop: 20 }}>
                <Typography>Everything included in Free plus:</Typography>
              </div>
              <Divider style={{ marginTop: 10 }} />
              <Typography>
                <Check style={{ marginTop: 5, marginRight: 10 }} />
                3000 questions / month
              </Typography>
              <Typography>
                <Check style={{ marginTop: 5, marginRight: 10 }} />
                50 training documents
              </Typography>
              <Typography>
                <Check style={{ marginTop: 5, marginRight: 10 }} />
                Access to live data from search engines and web browser
              </Typography>
              <Typography>
                <Check style={{ marginTop: 5, marginRight: 10 }} />
                Access to AI image generator
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6}>
        <Divider textAlign="left">For Businesses</Divider>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Card
              variant="outlined"
              style={{
                margin: 10,
                height: height,
                padding: 10,
                backgroundColor: "transparent",
              }}
            >
              <Typography variant="h5">Business</Typography>
              <Typography style={{ marginTop: 10 }}>
                For fast-moving teams looking to supercharge collaboration
              </Typography>
              <Box sx={{ flexDirection: "row", display: "flex" }}>
                <Typography variant="h3" sx={{ mt: 1 }}>
                  {billingPeriod === "annual" ? "$12" : "$25"}
                </Typography>
                <Typography sx={{ mt: 1.5, ml: 1 }}>
                  per user / month
                </Typography>
              </Box>
              {billingPeriod === "annual" && (
                <Typography variant="body1" sx={{ mt: 1 }}>
                  <strong>$144</strong> per user/year
                </Typography>
              )}
              {getButton("business")}
              <div style={{ marginTop: 20 }}>
                <Typography>Everything included in Individual:</Typography>
              </div>
              <Divider style={{ marginTop: 10 }} />
              <Typography>
                <Check style={{ marginTop: 5, marginRight: 10 }} />
                3000 questions / user / month
              </Typography>
              <Typography>
                <Check style={{ marginTop: 5, marginRight: 10 }} />
                50 training documents / user
              </Typography>
              <Typography>
                <Check style={{ marginTop: 5, marginRight: 10 }} />
                Integration with business applications like Slack, Notion,
                Google Docs, and more
              </Typography>
              <Typography>
                <Check style={{ marginTop: 5, marginRight: 10 }} />
                Dedicated agents for generating research reports
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card
              variant="outlined"
              style={{
                margin: 10,
                height: height,
                padding: 10,
                backgroundColor: "transparent",
              }}
            >
              <Typography variant="h5">Enterprise</Typography>
              <Typography style={{ marginTop: 10 }}>
                For innovative companies looking to scale securely
              </Typography>
              <div style={{ height: 66 }}></div>
              <Button
                variant="outlined"
                component={Link}
                href="mailto:sales@parallellabs.app"
                style={{ marginTop: 20 }}
                color="inherit"
              >
                Contact Sales
              </Button>
              <div style={{ marginTop: 20 }}>
                <Typography>Everything included in Business:</Typography>
              </div>
              <Divider style={{ marginTop: 10 }} />
              <Typography>
                <Check style={{ marginTop: 5, marginRight: 10 }} />
                Unlimited, high speed access to top models like GPT-4, Gemini,
                and Claude
              </Typography>
              <Typography>
                <Check style={{ marginTop: 5, marginRight: 10 }} />
                Single Sign-On (SSO) and domain verification
              </Typography>
              <Typography>
                <Check style={{ marginTop: 5, marginRight: 10 }} />
                On-premise deployment
              </Typography>
              <Typography>
                <Check style={{ marginTop: 5, marginRight: 10 }} />
                Access to API
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
