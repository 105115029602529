export default function ({ icon, name }: { icon: any; name: any }) {
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ marginRight: 10 }}>{icon}</div>
        <div>{name}</div>
      </div>
    </div>
  );
}
