import PreviewOutlined from "@mui/icons-material/PreviewOutlined";
import {
  Dialog,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import WorkflowRunPreview from "./WorkflowRunPreview";
import { hostname, parseError } from "./utils";

export default ({
  open,
  onClose,
  workflow,
  getToken,
  companyId,
  eventListener,
}: {
  open: boolean;
  onClose: any;
  workflow: any;
  getToken: Function;
  companyId: string;
  eventListener: Function;
}) => {
  const [loading, setLoading] = useState(false);
  const [workflowRuns, setWorkflowRuns] = useState<Array<any>>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [selectedWorkflowRun, setSelectedWorkflowRun] = useState<any>({});

  const fetchWorkflowRuns = async () => {
    const token = await getToken();
    setLoading(true);
    await axios
      .get(`${hostname}/workflows/runs`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          companyId,
          workflowId: workflow.id,
        },
      })
      .then((res) => {
        setWorkflowRuns(res.data.workflowRuns);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (workflow && open) {
      fetchWorkflowRuns();
    }
  }, [open, workflow]);

  const openPreview = (workflowRun: any) => {
    setPreviewOpen(true);
    setSelectedWorkflowRun(workflowRun);
  };

  return (
    <React.Fragment>
      <WorkflowRunPreview
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        workflowRun={selectedWorkflowRun}
      />
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogContent>
          <Typography>Workflow {workflow.name} Runs</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Inputs</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {workflowRuns.map((workflow) => (
                <TableRow key={workflow.id}>
                  <TableCell>{workflow.inputValues}</TableCell>
                  <TableCell>{workflow.created}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => openPreview(workflow)}>
                      <PreviewOutlined />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
