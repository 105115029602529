import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export default ({
  value,
  onChange,
}: {
  value: string;
  onChange: (event: any) => void;
}) => {
  return (
    <FormControl fullWidth sx={{ mt: 2 }}>
      <InputLabel>Output</InputLabel>
      <Select label="Output" value={value || "log"} onChange={onChange}>
        <MenuItem value="log">Log</MenuItem>
        <MenuItem value="document">Document</MenuItem>
      </Select>
    </FormControl>
  );
};
