import Close from "@mui/icons-material/Close";
import DragIndicator from "@mui/icons-material/DragIndicator";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { User } from "firebase/auth";
import { useCallback, useEffect, useMemo, useState } from "react";
import ReactFlow, {
  Background,
  Panel,
  addEdge,
  useEdgesState,
  useNodesState,
} from "reactflow";
import "reactflow/dist/style.css";
import { v4 as uuidv4 } from "uuid";
import WorkflowControls from "./WorkflowControls";
import WorkflowMiniMap from "./WorkflowMiniMap";
import WorkflowNodeEditor from "./WorkflowNodeEditor";
import WorkflowNodeType from "./WorkflowNodeType";
import { Company } from "./types";
import { hostname, parseError } from "./utils";

const LIST_ITEMS = [
  {
    key: "input",
    primary: "Input",
    secondary: "User inputs",
  },
  {
    key: "llm",
    primary: "LLM AI",
    secondary: "Call LLM AI using input",
  },
  {
    key: "fields",
    primary: "Extract Fields",
    secondary: "Extract fields from input",
  },
  {
    key: "output",
    primary: "Output",
    secondary: "Output location",
  },
];

const generateDefaultDocName = () => {
  const prefix = "Untitled Workflow";
  const timestamp = Date.now().toString();
  return `${prefix} ${timestamp}`;
};

export default ({
  open,
  onClose,
  mode,
  currentUser,
  getToken,
  eventListener,
  companyId,
  company,
  workflow,
  currentPlan,
}: {
  open: boolean;
  onClose: () => void;
  mode: string;
  getToken: Function;
  eventListener: Function;
  currentUser: any | User;
  companyId: string;
  company: Company;
  workflow: any;
  currentPlan: string;
}) => {
  const [nodes, setNodes, onNodesChange] = useNodesState(workflow.nodes || []);
  const [edges, setEdges, onEdgesChange] = useEdgesState(workflow.edges || []);
  const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);
  const [name, setName] = useState(workflow.name || generateDefaultDocName());
  const [id, setId] = useState(workflow.id);
  const [loading, setLoading] = useState(false);
  const [selectedNode, setSelectedNode] = useState<any>({});

  useEffect(() => {
    setName(workflow.name || generateDefaultDocName());
    setId(workflow.id);
    setNodes(workflow.nodes || []);
    setEdges(workflow.edges || []);
  }, [workflow]);

  const onConnect = useCallback(
    (params: any) =>
      setEdges((eds) => addEdge({ ...params, animated: true }, eds)),
    [setEdges]
  );

  const onDragStart = (event: any, type: string) => {
    event.dataTransfer.setData("application/reactflow", type);
    event.dataTransfer.effectAllowed = "move";
  };

  const onDragOver = useCallback((event: any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDelete = useCallback(
    (nodeId: string) => {
      setNodes((nds) => nds.filter((node) => node.id !== nodeId));
    },
    [nodes]
  );

  const onSelectionChange = (e: any) => {
    if (e.nodes.length > 0) {
      setSelectedNode(e.nodes[0]);
    }
  };

  const onDrop = useCallback(
    (event: any) => {
      event.preventDefault();

      console.log(event);
      const currentType = event.dataTransfer.getData("application/reactflow");
      const nodeType = LIST_ITEMS.find((item) => item.key === currentType);

      if (!nodeType) {
        console.log("Could not find node type", currentType);
        return;
      }

      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      console.log(position);

      const newNode: any = {
        id: uuidv4(),
        type: "WorkflowNodeType",
        position,
        data: {
          type: currentType,
          name: "",
          label: nodeType.primary,
          description: nodeType.secondary,
          onDelete: onDelete,
        },
      };
      const newNodes = [...nodes, newNode];
      console.log(newNodes);
      setNodes(newNodes);
    },
    [reactFlowInstance, nodes, edges]
  );

  const verifyWorkflow = async () => {
    const nodeDict: any = {};
    nodes.forEach((node) => {
      nodeDict[node.id] = node;
    });
    const graph: any = {};
    edges.forEach((edge) => {
      if (edge.source in graph) {
        graph[edge.source].append(edge.target);
      } else {
        graph[edge.source] = [edge.target];
      }
    });
    const inputNodes = nodes.filter((node) => node.data.type === "input");
    if (inputNodes.length > 1 && inputNodes.length > 0) {
      eventListener({
        type: "SET_ERROR",
        error: "Only one input node is allowed",
      });
      return false;
    }
    const inputNode = inputNodes[0];
    const outputNodes = nodes.filter((node) => node.data.type === "output");
    if (outputNodes.length > 0) {
      eventListener({
        type: "SET_ERROR",
        error: "Workflow must end with output node",
      });
      return false;
    }
    return true;
  };

  const saveWorkflow = async () => {
    console.log("Creating workflow");
    const verified = verifyWorkflow();
    if (!verified) return;
    const currentToken = await getToken();
    setLoading(true);
    await axios
      .post(
        `${hostname}/workflows`,
        {
          companyId,
          workflow: {
            id,
            name,
            nodes,
            edges,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      )
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const setNode = (node: any) => {
    setNodes((nds) =>
      nds.map((n) => {
        if (n.id === node.id) {
          return node;
        }
        return n;
      })
    );
  };

  const nodeTypes = useMemo(() => ({ WorkflowNodeType: WorkflowNodeType }), []);

  return (
    <Dialog
      open={open}
      fullScreen
      color="primary"
      PaperProps={{
        color: "primary",
        style: { backgroundColor: mode === "dark" ? "#000" : "#fff" },
      }}
    >
      <DialogTitle>
        <TextField
          sx={{ ml: 2, mt: 1 }}
          label="Workflow Name"
          size="small"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Button
          variant="outlined"
          sx={{ ml: 1, mt: 1, height: 40 }}
          onClick={saveWorkflow}
          startIcon={loading ? <CircularProgress size={20} /> : <></>}
        >
          Save
        </Button>
        <IconButton sx={{ float: "right" }} onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ReactFlow
          onInit={setReactFlowInstance}
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onSelectionChange={onSelectionChange}
          onDrop={onDrop}
          nodeTypes={nodeTypes}
          onDragOver={onDragOver}
          fitView
        >
          <Panel position="top-left">
            <Paper sx={{ p: 2 }} variant="outlined">
              <Typography variant="body1">
                Drag and drop nodes to the canvas
              </Typography>
              <List>
                {LIST_ITEMS.map((item) => (
                  <ListItem
                    key={item.key}
                    draggable
                    onDragStart={(e) => onDragStart(e, item.key)}
                    sx={{ cursor: "grab" }}
                  >
                    <ListItemText
                      primary={item.primary}
                      secondary={item.secondary}
                    />
                    <DragIndicator sx={{ ml: 1 }} />
                  </ListItem>
                ))}
              </List>
            </Paper>
            <Paper
              sx={{
                p: 2,
                mt: 2,
                mb: 1,
                width: 340,
                maxHeight: "50vh",
                overflow: "scroll",
              }}
              variant="outlined"
            >
              <WorkflowNodeEditor
                nodes={nodes}
                edges={edges}
                node={selectedNode}
                setNode={setNode}
                currentPlan={currentPlan}
              />
            </Paper>
          </Panel>
          <WorkflowControls mode={mode} />
          <WorkflowMiniMap mode={mode} />
          <Background gap={12} size={1} />
        </ReactFlow>
      </DialogContent>
    </Dialog>
  );
};
