import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import LoadingButton from "./LoadingButton";
import { hostname, parseError } from "./utils";

export default ({
  open,
  onClose,
  getToken,
  eventListener,
  companyId,
}: {
  open: boolean;
  onClose: () => void;
  getToken: Function;
  eventListener: Function;
  companyId: string;
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [blogDomain, setBlogDomain] = useState("");
  const [blogUsername, setBlogUsername] = useState("");
  const [blogPassword, setBlogPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState<any>({});

  const steps = ["type", "login", "sync"];

  const handleNext = async () => {
    console.log("Next");
    setLoading(true);
    if (activeStep === 1) {
      const currentToken = await getToken();
      await axios
        .post(
          `${hostname}/blogs/import/verify`,
          {
            blogDomain,
            blogUsername,
            blogPassword,
          },
          {
            headers: { Authorization: `Bearer ${currentToken}` },
          }
        )
        .then((res) => {
          console.log(res.data);
          setPosts(res.data.posts);
          setCategories(res.data.categories);
        })
        .catch((err) => {
          eventListener({
            type: "SET_ERROR",
            error: parseError(err),
          });
        });
    }
    setActiveStep(activeStep + 1);
    setLoading(false);
  };

  const handleFinishSubmit = async () => {
    console.log("Handle import blog");
    const currentToken = await getToken();
    await axios
      .post(
        `${hostname}/blogs/import`,
        {
          blogDomain,
          blogUsername,
          blogPassword,
          posts,
          categories,
        },
        {
          headers: { Authorization: `Bearer ${currentToken}` },
        }
      )
      .then((res) => {
        onClose();
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Import Blog</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Choose options for a generating an entire blog.
        </DialogContentText>
        <Stepper activeStep={activeStep} sx={{ mt: 2 }}>
          <Step>
            <StepLabel>Type</StepLabel>
          </Step>
          <Step>
            <StepLabel>Credentials</StepLabel>
          </Step>
          <Step>
            <StepLabel>Summary</StepLabel>
          </Step>
        </Stepper>
        {activeStep === 0 && (
          <Box sx={{ mt: 3 }}>
            <Typography sx={{ mb: 2 }}>
              Choose type of blog to import
            </Typography>

            <Button onClick={handleNext} sx={{ p: 4 }} variant="outlined">
              <img
                style={{
                  objectFit: "cover",
                  maxWidth: "100%",
                }}
                src={require("./img/wordpress.png")}
              ></img>
            </Button>
          </Box>
        )}
        {activeStep === 1 && (
          <Box sx={{ mt: 3 }}>
            <Typography sx={{ mb: 2 }}>
              Provide credentials for authenticating with the API
            </Typography>
            <TextField
              fullWidth
              sx={{ mb: 2 }}
              label="Blog Domain"
              value={blogDomain}
              onChange={(e) => setBlogDomain(e.target.value)}
            />
            <TextField
              fullWidth
              label="Username or Email"
              sx={{ mb: 2 }}
              value={blogUsername}
              onChange={(e) => setBlogUsername(e.target.value)}
            />
            <TextField
              fullWidth
              type="password"
              label="Password (Application Password)"
              helperText="Application passwords can be created in your wordpress admin site. https://{wordpress_domain}/wp-admin/profile.php"
              sx={{ mb: 2 }}
              value={blogPassword}
              onChange={(e) => setBlogPassword(e.target.value)}
            />
          </Box>
        )}
        {activeStep === 2 && (
          <Box sx={{ mt: 3 }}>
            <Typography sx={{ mt: 2 }}>
              Website: <strong>{`https://${blogDomain}`}</strong>
            </Typography>
            <Typography sx={{ mt: 2 }}>
              Posts to import: <strong>{posts.length}</strong>
            </Typography>
            <Typography sx={{ mt: 2 }}>
              Categories to import:{" "}
              <strong>{Object.keys(categories).length}</strong>
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose} sx={{ mt: 2 }}>
          Cancel
        </Button>
        <LoadingButton
          variant="text"
          onClick={
            activeStep === steps.length - 1 ? handleFinishSubmit : handleNext
          }
          sx={{ mt: 2 }}
          loading={loading}
        >
          {activeStep === steps.length - 1 ? "Finish" : "Next"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
