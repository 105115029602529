import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
} from "@mui/material";

const ParallelDialog = ({
  open,
  title,
  content,
  buttonText,
  onClick,
  onClose,
}: {
  open: boolean,
  title: string,
  content: string,
  buttonText: string,
  onClick: Function,
  onClose: Function,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClick} autoFocus>
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ParallelDialog;
